export const BASE_URL = "https://lihamoto.com"
export const ACTION = [
    {id:1,label:"ADD",value:'ADD'},{id:2,label:'RETURN',value:'RETURN'}
]

export const TAX = [
    {id:1,label:"0%",value:'0.00'},{id:2,label:'13%',value:'13.00'}
]

export const USER_TYPE = [
    {   
        id:"1",
        label:"ADMIN",
        value:"ADMIN"
    },
    {   
        id:"2",
        label:"BRANCHUSER",
        value:"BRANCHUSER"
    },
    {   
        id:"3",
        label:"NORMAL",
        value:"NORMAL"
    }
]

export const COUNTRY = [
    {   
        id:"1",
        label:"Nepal",
        value:"Nep"
    },
    {   
        id:"2",
        label:"China",
        value:"Ch"
    }
]

export const GENDER = [
    {   
        id:"1",
        label:"Male",
        value:"Male"
    },
    {   
        id:"2",
        label:"Female",
        value:"Female"
    },
    {   
        id:"3",
        label:"Other",
        value:"Other"
    }
]

export const CURRENCY = [
    {
        id:"1",
        label:"Nrs",
        value:"NEPALESE_RUPEE"
    }
]

export const VERIFIED = [
    {
        id:"1",
        label:"Not Verified",
        value:false
    },
    {
        id:"2",
        label:"Verified",
        value:true
    }
    
]

export const PAID = [
    {
        id:"1",
        label:"Not Paid",
        value:false
    },
    {
        id:"2",
        label:"Paid",
        value:true
    }
    
]

export const DISCARDED = [
    {
        id:"1",
        label:"Not Discarded",
        value:false
    },
    {
        id:"2",
        label:"Discarded",
        value:true
    }
    
]

export const STATUS = [
    {
        id:"1",
        label:"INACTIVE",
        value:"INACTIVE"
    },
    {
        id:"2",
        label:"ACTIVE",
        value:"ACTIVE"
    },
    {
        id:"3",
        label:"PENDING",
        value:"PENDING"
    }
]

export const ORDER_STATUS = [
    {
        id:1,
        label:"DUE",
        value:"DUE"
    },
    {
        id:2,
        label:"PAID",
        value:"PAID"
    },{
        id:3,
        label:"PROFORMA",
        value:"PROFORMA"
    },{
        id:4,
        label:"CANCELLED",
        value:"CANCELLED"
    },{
        id:5,
        label:"RETURNED",
        value:"RETURNED"
    },
]

export const SHIPMENT_STATUS = [
    {
        id:1,
        label:"PENDING",
        value:"PENDING"
    },
    {
        id:2,
        label:"PACKING",
        value:"PACKING"
    },
    {
        id:3,
        label:"READY",
        value:"READY"
    },{
        id:4,
        label:"ON_ROAD",
        value:"ON_ROAD"
    },
    {
        id:5,
        label:"DELIVERED",
        value:"DELIVERED"
    },
]

export const PAYMENT_METHOD = [
    {
        id:1,
        label:"BANK",
        value:"BANK"
    },
    {
        id:2,
        label:"CHEQUE",
        value:"CHEQUE"
    },
    {
        id:3,
        label:"CASH",
        value:"CASH"
    },
]