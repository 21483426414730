import { Box } from "@mui/material";
import Layout from "../../../components/Layout";
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LabelInput from "../../../components/LabelInput";
import LabelTextarea from "../../../components/LabelTextarea";
import SubmitButton from "../../../components/SubmitButton";
import StorefrontIcon from '@mui/icons-material/Storefront';
import FileInput from "../../../components/FileInput";

const CompanyDetailEdit = () => {

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/store">
                    <Box display={"flex"} gap={"5px"}>
                        <StorefrontIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Company"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <StorefrontIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Company Detail"}/>
                </Box>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <StorefrontIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit"}/>
                </Box>
            </Box>
                <form>
                    <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Company Detail"} />
                            <input type="hidden" />
                            <LabelInput name="companyName" type={"text"} label="Company Name" required />
                            <LabelTextarea name="aboutCompany" type={"text"} label="About Company" required />
                            <LabelTextarea name="vision" type={"text"} label="Vision" required />
                            <LabelTextarea name="mission" type={"text"} label="Mission" required />
                            <LabelInput name="homeVideoThumbnail" type={"text"} label="Video Thumbnail" required />
                            <LabelInput name="homeVideoUrlID" type={"text"} label="Video Url ID" required />
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <LabelInput name="primaryPhone" type={"text"} label="Primary Phone" required />
                            <LabelInput name="secondaryPhone" type={"text"} label="Secondary Phone" required />
                            <LabelInput name="primaryEmail" type={"text"} label="Primary Email" required />
                            <LabelInput name="secondaryEmail" type={"text"} label="Secondary Email" required />
                            <LabelInput name="facebook" type={"text"} label="facebook" required />
                            <LabelInput name="instagram" type={"text"} label="instagram" required />
                            <LabelInput name="tweeter" type={"text"} label="tweeter" required />
                            <LabelInput name="youtube" type={"text"} label="youtube" required />
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <LabelInput name="contactUsDescription" type={"text"} label="Contact Us Description" required />
                            <LabelInput name="contactUsFeedbackDescription" type={"text"} label="Contact Us Feedback" required />
                            <LabelInput name="contactUsSupportDescription" type={"text"} label="Contact Us Support" required />
                            <LabelInput name="headQuarterAddress" type={"text"} label="Head Quarter Address" required />
                            <LabelInput name="headQuarterPostalCode" type={"text"} label="Head Postal Code" required />
                            <LabelInput name="headQuarterCountry" type={"text"} label="Head Quarter Country" required />
                            <LabelTextarea name="headQuarterMap" type={"text"} label="Head Quarter Map" required />
                        </Box>
                    </Box>
                    <SubmitButton />
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"} mt={"1rem"}>
                        <FileInput />
                        <FileInput />
                        <FileInput />
                        <FileInput />
                    </Box>
                </form>
        </Layout>
    )
}
export default CompanyDetailEdit;