import { Box } from "@mui/material";
import { SubTitle } from "./SubTitle";

const LabelInput = ({label,required,name,register, type, value, max,size, disabled,min}) => {

    const style = {
        border:"1px solid lightgrey", 
        padding:"5px",
        borderRadius:"5px",
        width:size?size:"250px",
        ':focus': {
            border: '2px solid red!important'
        }
      };

    return (
        <Box display={"flex"} gap={"5px"}>
            {
                label &&
                <Box width={"120px"} height={"auto"} alignContent={"center"}>
                    <SubTitle title={label+(required?"*":"")} />
                </Box>
            }
            {
                register ?
                <input defaultValue={value} type={type} min={min==="negative"?"":0} max={max?max:""} {...register(name)} style={style} required={required?true:false} disabled={disabled?true:false}/>
                :<input defaultValue={value} type={type} style={style} required disabled={disabled?true:false}/>
            }
        </Box>
    )
}

export default LabelInput;