import { useEffect, useState } from "react";

import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { useNavigate } from "react-router-dom";
import LabelSelect from "../../../components/LabelSelect";
import { useGetApi } from "../../../components/api/useGetApi";
import LabelInput from "../../../components/LabelInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";

const ProductReport = () =>{
    const endPointPost = `affiliate-report/`; 
    const imageName = null; 
    const successMessage = "Report Generated!"; 
    const redirection = null; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess:success,
        handleSubmit,
        register,
        onFinalSubmit,
        data:response,
      } = usePostPut(endPointPost,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", flexDirection:'column', gap:"10px"}}>
                <Box display={"flex"} gap={"5px"}>
                    <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Affiliate Report"}/>
                </Box>
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} sx={{flexWrap:"wrap", gap:"10px"}}>
                        <LabelInput register={register} name={"start_date"} type={"date"} label={"Start Date"} required />
                        <LabelInput register={register} name={"end_date"} type={"date"} label={"End Date"} required />
                        <SubmitButton label={"Run"} noMargin={true} />
                    </Box>
                </form>
            </Box>

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Markerter</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Visits</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Product</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Conversion Count</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Verified Conversions</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Commission Amount</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                        {
                            response &&
                            Array.isArray(response?.data) &&
                            response?.data?.map((item)=>(
                                <TableRow key={item.id} sx={{cursor:"pointer", '&:last-child td, &:last-child th': { border: 0 }}} >
                                    <TableCell component="th" scope="row">
                                    {item.marketer}
                                    </TableCell>
                                    <TableCell align="left">{item.visits}</TableCell>
                                    <TableCell align="left">{item.inventoryGroup}</TableCell>
                                    <TableCell align="left">{item.conversion_count}</TableCell>
                                    <TableCell align="left">{item.total_verified_conversion}</TableCell>
                                    <TableCell align="left">{item.total_verified_commission}</TableCell>
                                </TableRow>
                            ))
                        }
                </TableBody>
            </Table>
            
        </Layout>
    )
}

export default ProductReport;