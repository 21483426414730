import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect } from "react";
import LabelSelect from "../../../components/LabelSelect";
import LabelInput from "../../../components/LabelInput";
import SubmitButton from "../../../components/SubmitButton";
import { ACTION } from "../../../constants/CONSTANTS";
import LabelTextarea from "../../../components/LabelTextarea";
import usePostPut from "../../../components/api/usePostPut";

const EditInventoryItemQuantity = () => {

    const params = useParams();
    const endPoints = [
        '/api/inventory-item/?group='+params.id,
    ]
    const {data, refetch:fetch} = useGetChainApi(endPoints);

    useEffect(()=>{
        fetch()
    },[])

    const updateEndpoint = 'edit-inventory-item-quantity/'
    const imageName = null; 
    const successMessage = "Item Quantity Updated!"; 
    const redirection = `/inventory/detail/${params?.id}/`; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(updateEndpoint,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <Layout>
            <Box display={"flex"} justifyContent={"space-between"} sx={{borderBottom:"1px solid lightgray"}}>
                <Box sx={{display:"flex",paddingBottom:"5px", gap:"5px"}}>
                    <Link to="/inventory/">
                        <Box display={"flex"} gap={"5px"}>
                            <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Inventory"}/>
                        </Box>
                    </Link>
                    <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Inventory Item"}/>
                    </Box>
                    <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Edit Quantity"}/>
                    </Box>
                </Box>

            </Box>
            <Box sx={{display:"flex", flexDirection:"column", gap:"5px"}}>
                <Title title={"Edit Admin Inventory"}/>
                <Box fontStyle={"italic"} fontSize={"13px"}  maxWidth={"400px"}>
                    You are updating admin quantity of this product. From this form, you can add new stock to Admin/Head office or reduce stock from Admin/Head Office.
                    <br></br>
                </Box>
                <br></br>
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
                        {
                            data?.length > 0 &&
                            Array.isArray(data[0]?.data) &&
                            <>
                                <LabelSelect register={register} label={"Inventory Item"} name={"inventoryItem"} data={data[0]?.data?.map((item)=>(
                                    {
                                        label:"Total "+item?.quantity+" "+item?.title,
                                        value:item?.id,
                                    }
                                ))} />
                            </>
                        }
                        <LabelSelect register={register} name={"action"} data={ACTION} label={"Action"}/>
                        <LabelInput register={register} label={"Quantity"} name={"quantity"} />
                        <LabelTextarea register={register} name={"remark"} label={"Remark"} />
                        <SubmitButton />
                    </Box>
                </form>

            </Box>
        </Layout>
    )
}

export default EditInventoryItemQuantity;