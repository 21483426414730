import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import LabelSelect from "../../../components/LabelSelect";
import usePostPut from "../../../components/api/usePostPut";
import SubmitButton from "../../../components/SubmitButton";
import LabelInput from "../../../components/LabelInput";
import { useEffect, useState } from "react";
import { CURRENCY, STATUS } from "../../../constants/CONSTANTS";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LabelTextarea from "../../../components/LabelTextarea";

const AddInventoryGroup =() => {
    const [textValue, setTextValue] = useState("");
    const endPoint = "inventories/"; 
    const imageName = null; 
    const successMessage = "Inventory Group Added!"; 
    const redirection = "/inventory"; 
    const type = "post";
    const goToDetailedPage= true;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        control
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);
      

    const endPoints = [
        '/api/categories/',
        '/api/brands/',
        '/api/vendors/?status=ACTIVE'
    ]
    const {data,refetch,isSuccess:success} = useGetChainApi(endPoints);

    useEffect(()=>{
        refetch();
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/inventory/">
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Inventory Group"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Add Inventory Group"}/>
                </Box>
            </Box>

            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Box>
                            <Title title={"Inventory Group"} />
                            <Box sx={{fontSize:"12px",fontStyle:"italic"}}>Note: Once you create group, you may add multiple products, models or colors of products.</Box>
                        </Box>
                        <LabelInput register={register} name="title" type={"text"} label="Group Name"/>
                        {
                            data?.length > 0 &&
                            Array.isArray(data[0]?.data) &&
                            <LabelSelect register={register}  name={"category"} label="Select Category" data={data[0]?.data.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))} required/>
                        }

                        {
                            data?.length > 1 &&
                            Array.isArray(data[1]?.data) &&
                            success &&
                            <LabelSelect register={register} name={"brand"} label="Select Brand" data={data[1]?.data?.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))} required/>
                        }

                        {
                            data?.length > 2 &&
                            Array.isArray(data[2]?.data) &&
                            <LabelSelect register={register} name={"vendor"} label="Select Vendor" data={data[2]?.data.map(item => ({
                                value: item.id,
                                label: item.companyName,
                            }))} required/>
                        }
                        <LabelInput register={register} name="commission" type={"number"} label="Commission %"/>
                        <LabelSelect register={register} name={"currency"} label="Select Currency" data={CURRENCY} required/>
                        <LabelTextarea register={register} name="description" label="Description" required />
                        <Box mt="15px">
                        <Title title={"More Description"} />
                        <Controller
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data={ textValue }
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setTextValue(data);
                                        onChange(data)
                                    }}
                                />
                            )}
                            name='moreDescription'
                            control={control}
                            defaultValue=''
                            />
                </Box>
                    </Box>
                </Box>
                <SubmitButton />
            </form>
        </Layout>
    )
}

export default AddInventoryGroup;