import { Box } from "@mui/material";
import LabelInput from "../../../components/LabelInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";

const ChangePassword = () => {

    const endPoint = "profile/changepassword/"; 
    const imageName = null; 
    const successMessage = "Password Updated!"; 
    const redirection =null; 
    const type = "post";//using post for update, since we are using same api for create and update
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <> <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                    <LabelInput register={register} name="old_password" type={"password"} label="Old Password" required/>
                    <LabelInput register={register} name="new_password" type={"password"} label="New Password" required/>
                    <LabelInput register={register} name="new_password2" type={"password"} label="Confirm Password" required/>
                </Box>
                <SubmitButton />
            </form>
        </>
    )
}

export default ChangePassword;