import { Button } from "@mui/material"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { NavLink } from "react-router-dom";

const EditButton =({path}) => {
    return (
        <NavLink to={path?path:"edit"}>
            <Button sx={{my:1,alignContent:"center", fontSize:"10px",backgroundColor:"var(--light-brown)", border:`1px solid var(--dark-brown)`, paddingX:"1rem"}}>
                <ModeEditIcon sx={{fontSize:"14px", marginRight:"5px"}} /> <span style={{marginTop:'2px'}}>Edit</span>
            </Button>
        </NavLink>
    )
}

export default EditButton;