import { useEffect, useState } from "react";

import { Box, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import BusinessIcon from '@mui/icons-material/Business';
import { Link, useParams } from "react-router-dom";

import { Title } from "../../../components/Title";
import Layout from "../../../components/Layout"
import Tag from "../../../components/Tag";
import LeftLabelText from "../../../components/LeftLabelText";
import CustomTabPanel from "../../../components/CustomTabPanel";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import AffiliateConversionEdit from "../edit/AffiliteConversionEdit";

const AffiliateLinkDetail = () => {

    const params = useParams();
    const endPoints = [`/api/get-affiliate-link-detail/${params.id}/`];
    const {data, refetch:fetch} = useGetChainApi(endPoints);

    useEffect(()=>{
        fetch();
    },[fetch])

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
      }

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/affiliate-links/">
                    <Box display={"flex"} gap={"5px"}>
                        <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Affiliate Links"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Affiliate Link Detail"}/>
                </Box>
            </Box>

            {
                data?.length > 0 &&
                <>
                    <Box sx={{display:"flex", flexDirection:"column", gap:"5px"}}>
                        <Box>
                            <LeftLabelText label={"Tracking ID"} value={data[0]?.data?.tracking_id}/>
                            <LeftLabelText label={"User"} value={data[0]?.data?.user?.name}/>
                            <LeftLabelText label={"Product"} value={data[0]?.data?.inventoryGroup?.title}/>
                            <LeftLabelText label={"Commission %"} value={data[0]?.data?.commission}/>
                            <LeftLabelText label={"Link Visits"} value={data[0]?.data?.visits}/>
                            <LeftLabelText label={"Total Conversions"} value={data[0]?.data?.conversion_count}/>
                        </Box>
                    </Box>
                    <Box sx={{borderBottom:"1px solid lightgray", marginTop:"16px"}}></Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="tabs" indicatorColor={'secondary'}>
                            <Tab label="Conversions" {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        
                        <Box display={"flex"} flexWrap={"wrap"} gap={"15px"}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead >
                            <TableRow >
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">Order #</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">is Verified?</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">is Paid?</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">is Discarded?</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">Commission Amount</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data?.length > 0 &&
                                    data[0]?.data &&
                                    Array.isArray(data[0]?.data?.conversions) &&
                                    data[0]?.data?.conversions?.length > 0 &&
                                    data[0]?.data?.conversions?.map((item)=>(
                                        <AffiliateConversionEdit key={item.id} item={item} />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        </Box>
                    </CustomTabPanel>
                </>
            }
        </Layout>
    )
}

export default AffiliateLinkDetail;