import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

const InventoryGroupListComponent =({data,branchId})=> {
    const navigate = useNavigate();

    return (
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead >
            <TableRow >
                <TableCell sx={{color:"var(--primary-color)",width:"10rem"}} >Name</TableCell>
                <TableCell sx={{color:"var(--primary-color)"}} align="left">Category</TableCell>
                <TableCell sx={{color:"var(--primary-color)"}} align="left">Brand</TableCell>
                <TableCell sx={{color:"var(--primary-color)"}} align="left">Vendor</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {
                    Array.isArray(data) &&
                    data?.map((item)=>(
                        <TableRow onClick={()=>navigate(
                            (branchId || localStorage.getItem("userType") === "BRANCHUSER")
                            ?`/branch/inventory/detail/${item.id}/${branchId?branchId:localStorage.getItem("branchId")}/`
                            :`/inventory/detail/${item.id}/`)}
                            key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor:"pointer"}} >
                            <TableCell component="th" scope="row">{item?.title}</TableCell>
                            <TableCell align="left">{item?.category?.name}</TableCell>
                            <TableCell align="left">{item?.brand?.name}</TableCell>
                            <TableCell align="left">{item?.vendor?.companyName}</TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )
}

export default InventoryGroupListComponent;