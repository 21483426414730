import { useQuery } from "react-query";
import { authorizationAxios } from "../../axios/authorizationAxios";
import axios from "axios";

export const useGetChainApi = (endpoints) => {

  async function getChainApi() {
    
    const requests = endpoints.map((endpoint) => authorizationAxios.get(endpoint));

    const res = await axios.all(requests);
      
    return res;
  }

  const now = new Date();
  
  // const year = now.getFullYear();
  // const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  // const day = String(now.getDate()).padStart(2, '0');
  // const hours = String(now.getHours()).padStart(2, '0');
  // const minutes = String(now.getMinutes()).padStart(2, '0');
  // const seconds = String(now.getSeconds()).padStart(2, '0');

  const { data, error, isError, isLoading, refetch, isSuccess,remove, } = useQuery(
    `getChainApi`,
    getChainApi,{enabled: false}
  );
  
  return {
    data,
    refetch,
    isSuccess,
    remove,
    isLoading
  };
};