import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserListComponent = ({data})=> {

    const navigate = useNavigate();
    

    return (
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Photo</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} >User Name</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Branch</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Phone</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Email</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">User Type</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Status</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.isArray(data) &&
                        data?.map((item)=>(
                            <TableRow onClick={()=>navigate(`/user/detail/${item.id}`)} key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor:"pointer"}} >
                                <TableCell component="th" scope="row">
                                    <img style={{height:"50px",borderRadius:"5px"}} src={item?.profile?.profilePicture} />
                                </TableCell>
                                <TableCell align="left">{item.first_name + " " + item.last_name}</TableCell>
                                <TableCell align="left">{item?.profile?.branch?.companyName}</TableCell>
                                <TableCell align="left">{item?.profile?.phone_ext + " " + item?.profile?.phone}</TableCell>
                                <TableCell align="left">{item?.email}</TableCell>
                                <TableCell align="left">{item?.profile?.userType}</TableCell>
                                <TableCell align="left">{item?.profile?.status}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
        </Table>
    )
}

export default UserListComponent;