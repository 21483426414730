import { Box } from "@mui/material";
import Layout from "../../components/Layout";
import { Title } from "../../components/Title";
import { Link } from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/Assessment';
import Counter from "../../components/Counter";

const ReportList = () => {
    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <AssessmentIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Reports"}/>
                </Box>
            </Box>
            <Box sx={{display:"flex", gap:"15px"}}>
                <Link to={"/reports/sales-report"}>
                    <Counter
                        bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                        count={"Order"}
                        label={"Sales Report"}
                    />
                </Link>
                {
                    localStorage.getItem("userType") === "ADMIN" &&
                    <>
                        <Link to={"/reports/product-performance"}>
                            <Counter
                                bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                                count={"Product"}
                                label={"Performance Report"}
                            />
                        </Link>
                        <Link to={"/reports/order-count-by-branch"}>
                            <Counter
                                bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                                count={"Branch"}
                                label={"Performance Report"}
                            />
                        </Link>
                        <Link to={'/reports/affiliate-report'}>
                            <Counter
                                bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                                count={"Affiliate"}
                                label={"Affiliate Report"}
                            />
                        </Link>
                    </>
                }
            </Box>
        </Layout>
    )
}
export default ReportList;