import { Box } from "@mui/material";
import Layout from "../../../components/Layout";
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import LabelInput from "../../../components/LabelInput";
import LabelTextarea from "../../../components/LabelTextarea";
import FileInput from "../../../components/FileInput";
import SubmitButton from "../../../components/SubmitButton";
import { useEffect } from "react";
import usePostPut from "../../../components/api/usePostPut";
import { useGetApi } from "../../../components/api/useGetApi";

const EditBrand = () => {

    const params = useParams();
    const endPoint = `brands/${params.id}/`;
    const {data, refetch:fetch} = useGetApi(endPoint);

    useEffect(()=>{
        fetch();
    },[])

    const imageName = "logo";
    const successMessage = "Brand Updated!"; 
    const redirection = `/brand/detail/${params.id}`; 
    const type = "put";
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type);

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/brand/">
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Brand"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Brand Detail"}/>
                </Box>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit"}/>
                </Box>
            </Box>
            {
                data?.data && 
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Brand Details"} />
                            <input {...register("id")} type="hidden" value={data?.data?.id} />
                            <LabelInput register={register} value={data?.data?.name} name="name" type={"text"} label="Brand Name" required />
                            <LabelInput register={register} value={data?.data?.color} name="color" type={"text"} label="Brand Color" required />
                            <LabelTextarea register={register} value={data?.data?.description} name="description" label="Description" required />
                        </Box>
                        <Box>
                            <Title title={"Logo"} />
                            <Box display={"flex"}>
                                <FileInput 
                                    uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                                    uploadPhotoFile={uploadPhotoFile}
                                    uploadPhotoRef={uploadPhotoRef}
                                    uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                                    image={data?.data?.logo}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <SubmitButton />
                </form>
            }
        </Layout>
    )
}
export default EditBrand;