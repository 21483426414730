import { Box } from "@mui/material";

const Tag = ({bg,color,tag,onClick}) => {
    return (
        <Box sx={{
            fontSize:"12px",
            backgroundColor:bg,
            color:color,
            padding:"4px",
            width:"fit-content",
            borderRadius:"5px",
            paddingX:"10px",
            cursor:"pointer",
            ":hover":{
                background:onClick?"var(--secondary-color)":bg,
                color:onClick?"white":color,
                transition: "background-color 0.5s ease"
            }
        }}
        onClick={onClick?onClick:()=>{}}
        >
            {tag}
        </Box>
    )
}

export default Tag