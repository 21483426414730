import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetChainApi } from "../../../components/api/useGetChainApi";

const LinkList = ({userId,conversions}) => {

    const navigate = useNavigate()
    const [page,setPage] = useState(1);

    const endPints = [
        `/api/get-user-affiliate-links/${userId?userId:"all"}/?conversions=${conversions}&page=${page}`
    ]

    const {data, refetch,isLoading} = useGetChainApi(endPints);

    useEffect(()=>{
        refetch();
    },[conversions, refetch, page])
    
    return (
        <>
            {
                data?.length > 0 &&
                <Pagination title={"Recent Affiliate Links"} setPage={setPage} next={ data && data[0]?.data?.next} previous={ data && data[0]?.data?.previous}/>
            }
            
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">User</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Product</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">visits</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">commission %</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">No. of Conversions</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {   
                        isLoading === false &&
                        data?.length > 0 &&
                        Array.isArray(data[0]?.data?.results) &&
                        data[0]?.data?.results?.map((item)=>(
                            <TableRow onClick={()=>navigate(`/affiliate-links/detail/${item.id}`)} key={item.id} sx={{cursor:"pointer", '&:last-child td, &:last-child th': { border: 0 }}} >
                                <TableCell component="th" scope="row">{item.user.name}</TableCell>
                                <TableCell align="left">{item.inventoryGroup.title}</TableCell>
                                <TableCell align="left">{item.visits}</TableCell>
                                <TableCell align="left">{item.commission}%</TableCell>
                                <TableCell align="left">{item.conversion_count}</TableCell>
                            </TableRow>
                        ))
                    }
                    {
                        isLoading &&
                        <Box>
                            Loading...
                        </Box>
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default LinkList;