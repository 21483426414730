import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Layout from "../../../components/Layout";
import { Link, useNavigate } from "react-router-dom";
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import { Title } from "../../../components/Title";
import { useGetApi } from "../../../components/api/useGetApi";
import { useEffect } from "react";
import Zoom from 'react-medium-image-zoom'

const CategoryList =()=> {

    const endPoint = `categories/`;
    const {data, refetch:fetch} = useGetApi(endPoint);
    const navigate = useNavigate();

    useEffect(()=>{
        fetch()
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <ElectricBikeIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Category"}/>
                </Box>
                <Link to="/category/add">
                    <Box display={"flex"} gap={"5px"} sx={{cursor:"pointer"}}>
                        <ElectricBikeIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Add Category"}/>
                    </Box>
                </Link>
            </Box>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)",width:"10rem"}} >Category Icon</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Name</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.isArray(data?.data) &&
                        data?.data?.map((item)=>(
                            <TableRow onClick={()=>navigate(`/category/detail/${item.id}/edit`)} key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor:"pointer"}} >
                                <TableCell component="th" scope="row">
                                    <Zoom>
                                        <img alt="category" style={{height:"50px",borderRadius:"5px"}} src={item.icon} />
                                    </Zoom>
                                </TableCell>
                                <TableCell align="left">{item.name}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Layout>
    )
}
export default CategoryList;