import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { usePostPutApi } from "./usePostPutApi";

export default function usePostPut(endpoint,imageName,successMessage,navigateTo,type,goToDetailedPage){
    const { postPutApiMutation } = usePostPutApi(endpoint,type);
    const { isLoading, isError, error, isSuccess, data } = postPutApiMutation;
    const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
      } = useForm();

      const onFinalSubmit = (values) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });
        if(imageName){
          uploadPhotoFile?.map((file) => formData.append(imageName, file));
        }
        postPutApiMutation.mutate(formData);
      };

      useEffect(() => {
        if (isSuccess) {
            toast.success(successMessage, {
                position: "top-right",
                autoClose: 2000,});
            if(goToDetailedPage){
              window.location.href = `${navigateTo}/detail/${data?.data?.id}/`;
            }else{
              if(navigateTo){
                window.location.href = navigateTo
              }
            }
        }
    
        if (isError) {
            
            toast.error(error?.response?.data?.error, {
              position: "top-right",
              autoClose: 2000,});
            toast.error(error?.request?.response, {
              position: "top-right",
              autoClose: 2000,});
          }
        }, [
          postPutApiMutation.isSuccess,
          postPutApiMutation.isLoading,
          postPutApiMutation.isError,
        ]);

        const uploadPhotoRef = useRef(null);

        function uploadPhotoOnClickHandler() {
          uploadPhotoRef.current.click();
        }

        function uploadPhotoChangeHandler(event) {
          SetUploadPhotoFile(Array.from(event.target.files));
        }

      return {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        watch,
        setValue,
        control,
        data
      };
    
}