import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import StoreIcon from '@mui/icons-material/Store';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Tag from "../../../components/Tag";
import { STATUS } from "../../../constants/CONSTANTS";
import { useGetApi } from "../../../components/api/useGetApi";
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { useLocation } from 'react-router-dom';

const BranchList = () => {

    const [status,setStatus] = useState("ALL");
    const endPoint = `branches/?status=${status}`;
    const {data,refetch:fetch,isSuccess} = useGetApi(endPoint);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        fetch()
    },[status, location.key, fetch])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <StoreIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Branch"}/>
                    <Tag onClick={()=>setStatus("ALL")} bg={"var(--light-blue)"} color={"var(--dark-blue)"} tag={"ALL"}/>
                    <Tag onClick={()=>setStatus(STATUS[1].value)} bg={"var(--light-green)"} color={"var(--dark-green)"} tag={STATUS[1].label}/>
                    <Tag onClick={()=>setStatus(STATUS[0].value)} bg={"var(--light-brown)"} color={"var(--dark-brown)"} tag={STATUS[0].label}/>
                    <Tag onClick={()=>setStatus(STATUS[2].value)} bg={"var(--light-purple)"} color={"var(--dark-purple)"} tag={STATUS[2].label}/>
                </Box>
                <Box display={"flex"} gap={"1rem"}>
                    <Link to="/branch/add">
                        <Box display={"flex"} gap={"5px"} sx={{cursor:"pointer"}}>
                            <AddBusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Add Branch"}/>
                        </Box>
                    </Link>
                    <Link to="/branch/inventory/add">
                        <Box display={"flex"} gap={"5px"}>
                            <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Assign Inventory"}/>
                        </Box>
                    </Link>
                </Box>
            </Box>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow>
                    <TableCell sx={{color:"var(--primary-color)"}} >Address</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="right">Company</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="right">Email</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="right">Phone</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="right">Inventory</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="right">Status</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.isArray(data?.data) &&
                        data?.data?.map((item)=>(
                            <TableRow onClick={()=>navigate(`/branch/detail/${item.id}`)} key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor:"pointer"}} >
                                <TableCell component="th" scope="row">
                                {item.street+", "+item.city+", "+item.country}
                                </TableCell>
                                <TableCell align="right">{item.companyName}</TableCell>
                                <TableCell align="right">{item.primaryEmail}</TableCell>
                                <TableCell align="right">{item.primaryPhone}</TableCell>
                                <TableCell align="right">{item.total_quantity?item.total_quantity:0}</TableCell>
                                <TableCell align="right">{item.branchStatus}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Layout>
    )
}

export default BranchList;