import { useQuery } from "react-query";
import { authorizationAxios } from "../../axios/authorizationAxios";

export const useGetApi = (endpoint) => {

  async function getApi() {
    const res = await authorizationAxios.get(
      `/api/${endpoint}`
    );
    return res;
  }

  const now = new Date();
  
  // const year = now.getFullYear();
  // const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  // const day = String(now.getDate()).padStart(2, '0');
  // const hours = String(now.getHours()).padStart(2, '0');
  // const minutes = String(now.getMinutes()).padStart(2, '0');
  // const seconds = String(now.getSeconds()).padStart(2, '0');
  // -${year}-${month}-${day} ${hours}:${minutes}:${seconds}
  const { data, error, isError, isLoading, refetch, isSuccess,remove } = useQuery(
    `getApi`,
    getApi,{enabled: false}
  );
  
  return {
    data,
    refetch,
    isSuccess,
    remove
  };
};