import { Box, Button } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { BASE_URL } from "../constants/CONSTANTS";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Zoom from 'react-medium-image-zoom'

const FileInput =({uploadPhotoOnClickHandler,uploadPhotoFile,uploadPhotoRef,uploadPhotoChangeHandler,image,multiple})=> {
    
    function downloadFile(file) {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <Box display={"flex"}>
            <Button sx={{width:"fit-content"}} onClick={uploadPhotoOnClickHandler}>
                <CloudUploadIcon sx={{height: '200px',width:"200px",border:"1px solid brown", borderRadius:"5px" }}/>
            </Button>
            <input
                type="file"
                accept=".pdf,.doc,.docx,image/*"
                hidden
                ref={uploadPhotoRef}
				onChange={uploadPhotoChangeHandler}
                multiple={multiple?multiple:false}
            />
            {uploadPhotoFile?.length !== 0 &&
			    uploadPhotoFile?.map((file) => {
			    	if (file.type.startsWith("image/")) {
                        // Display image
                        return (
                            <img
                                src={URL.createObjectURL(file)}
                                alt="Uploaded"
                                style={{
                                    height: '200px',
                                    width: "200px",
                                    objectFit: "cover",
                                    margin: "0.5rem",
                                    border: "1px solid brown",
                                    borderRadius: "5px"
                                }}
                            />
                        );
                    } else {
                        // Display download button for other file types
                        return (
                            <Box sx={{margin: "0.5rem",height: '200px',width:"200px",border:"1px solid brown", borderRadius:"5px",display:"flex",flexDirection:"column",alignItems:"center", justifyContent:"center" }}>
                                <FilePresentIcon sx={{height: '140px',width:"140px" }}/>
                                <p>{file.name}</p>
                            </Box>
                        );
                    }
			    })}
            {
                image && uploadPhotoFile?.length == 0&&
                <Zoom>
                    <img src={image.includes("http")?image:`${BASE_URL}${image}`} alt="Uploaded" style={{height: '200px',width:"200px", objectFit:"cover" ,margin:"0.5rem",border:"1px solid brown", borderRadius:"5px" }}/>
                </Zoom>
            }
        </Box>
    )
}

export default FileInput;