import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReceiptIcon from '@mui/icons-material/Receipt';
import useEditOrder from "./useEditOrder";
import LabelSelect from "../../../components/LabelSelect";
import {TAX } from "../../../constants/CONSTANTS";
import LabelSelectSearch from "../../../components/LabelSelectSearch";
import LeftLabelText from "../../../components/LeftLabelText";
import LabelInput from "../../../components/LabelInput";
import CloseIcon from '@mui/icons-material/Close';
import LabelTextarea from "../../../components/LabelTextarea";
import { SubTitle } from "../../../components/SubTitle";
import SubmitButton from "../../../components/SubmitButton";
import { useGetApi } from "../../../components/api/useGetApi";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect, useState } from "react";

const EditOrder = () => {

    const [subTotal,setSubTotal] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [total, setTotal] = useState(0);
    const [adj, setAdj] = useState(null); 
    const [search,setSearch] = useState("");

    const params = useParams();
    const {data:customer, refetch:fetch} = useGetApi(`users/?type=NORMAL&search=${search}`)
    const endpoint = ['/api/inventory-item/',`/api/order/${params.id}/`]
    const {data, refetch} = useGetChainApi(endpoint);

    useEffect(()=>{
        refetch();
    },[])

    useEffect(()=>{
        fetch()
    },[search])
    
    const {
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        watch,
        setValue,
        getValues,
        fields, 
        append, 
        remove,
        control
      } = useEditOrder("create-order-with-items/",data);

      function calculateAmount(qty,rate,dis,tax){
        const totalDiscount =dis * (qty * rate)/100;
        const totalTax = tax*((qty * rate) - dis * (qty * rate)/100)/100;
        const subTotal = qty * rate;
        const total = (subTotal - totalDiscount) + totalTax;
        return {total,subTotal,totalTax,totalDiscount};
      }

      const regex = /item\.(\d+)\./;
      const regexItem = /item\.(\d+)\.inventoryItem/;

      useEffect(() => {
          const subscription = watch((value, { name, type }) =>
          {
            if(name === "customer"){
                setSearch(value.customer)
            }
            if(name === "adjustmentAmount"){
                setAdj(parseFloat(value.adjustmentAmount));
            }

            const itemMatch = name.match(regexItem);
            const match = name.match(regex);

            if(itemMatch){
                if(data?.length > 0){
                    setValue(`item.${itemMatch[1]}.rate`,data[0]?.data?.filter((item)=>item.id === parseInt(getValues(`item.${itemMatch[1]}.inventoryItem`)))[0]?.price)
                }
            }
            if(match){
                setSubTotal(0);
                setTotalDiscount(0);
                setTotal(0);
                setTotalTax(0);
                value?.item?.map((item)=> 
                {
                    setSubTotal((prev)=>
                        prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                        ,parseFloat(item.discount),parseInt(item.tax)).subTotal
                    )
                    setTotalDiscount((prev)=>
                        prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                        ,parseFloat(item.discount),parseInt(item.tax)).totalDiscount
                    )
                    setTotalTax((prev)=>
                        prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                        ,parseFloat(item.discount),parseInt(item.tax)).totalTax
                    )
                    setTotal((prev)=>
                        prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                        ,parseFloat(item.discount),parseInt(item.tax)).total
                    )
                }
                )
                
                document.getElementById(`item.${match[1]}`).innerHTML = calculateAmount(
                        getValues(`item.${match[1]}.quantity`),
                        parseFloat(getValues(`item.${match[1]}.rate`)),
                        parseFloat(getValues(`item.${match[1]}.discount`)),
                        parseFloat(getValues(`item.${match[1]}.tax`))
                    ).total
            }
          }
        )
        return () => subscription.unsubscribe()
      }, [watch,data])


    useEffect(()=>{
        setSubTotal(0);
        setTotalDiscount(0);
        setTotal(0);
        setTotalTax(0);
        fields?.map((item)=> 
        {
            setSubTotal((prev)=>
                prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                ,parseFloat(item.discount),parseInt(item.tax)).subTotal
            )
            setTotalDiscount((prev)=>
                prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                ,parseFloat(item.discount),parseInt(item.tax)).totalDiscount
            )
            setTotalTax((prev)=>
                prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                ,parseFloat(item.discount),parseInt(item.tax)).totalTax
            )
            setTotal((prev)=>
                prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                ,parseFloat(item.discount),parseInt(item.tax)).total
            )
        }
        )
    },[fields])

    function removeOrderItem(index){
        remove(index);
    }

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to={`/order`}>
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Order"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Link to={`/order/detail/${params.id}`}>
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Order Detail"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit"}/>
                </Box>
            </Box>
            {
                data?.length > 1 &&
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Title title={"Edit Invoice/Order"}/>
                        <input type="hidden" {...register("id")} value={params.id}/>
                        
                        <LeftLabelText label={"Customer"} value={data[1]?.data?.customer?.name} />
                        <LabelInput register={register} name={"invoiceNumber"} label={"Invoice Number"} required />
                        <LabelInput register={register} name={"orderDate"} type={"date"} label={"Invoice Date"} required />
                        <LabelInput register={register} name={"dueDate"} type={"date"} label={"Due Date"} required />
                        <LabelInput register={register} name={"invoiceSubject"} label={"Invoice Subject"} required />
                        <Box fontStyle={"italic"} fontSize={"13px"}  maxWidth={"400px"}>
                            IMPORTANT: Invoice Rate cannot be updated. Invoice will show rate at which the item was sold, not current rate. But if you add new item, then it will show current price.
                        </Box>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead >
                            <TableRow >
                                <TableCell sx={{color:"var(--primary-color)"}} >Item Detail</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">Quantity</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">Rate</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">Discount</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">Tax</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">Amount</TableCell>
                                <TableCell sx={{color:"var(--primary-color)"}} align="left">Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {fields.map((field, index) => (
                                <TableRow key={field.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                    <TableCell component="th" scope="row">
                                        {/* <input type="hidden" {...register(`item.${index}.id`)} /> */}
                                        {
                                            data?.length > 0 &&
                                            <LabelSelectSearch 
                                                name={`item.${index}.inventoryItem`}
                                                control={control}
                                                options={
                                                    data[0]?.data?.map((item)=>(
                                                        {
                                                            label:item.title+"--"+item.quantity,
                                                            value:item.id
                                                        }
                                                    ))
                                                } />
                                        }

                                    </TableCell>
                                    <TableCell align="left"><LabelInput register={register} name={`item.${index}.quantity`} size={"50px"} type={"number"} value={0}  required/></TableCell>
                                    <TableCell align="left"><LabelInput register={register} name={`item.${index}.rate`} disabled size={"100px"} type={"number"} value={0}  required/></TableCell>
                                    <TableCell align="left"><LabelInput register={register} name={`item.${index}.discount`} size={"100px"} type={"number"} value={0}  required/></TableCell>
                                    <TableCell align="left"><LabelSelect register={register} name={`item.${index}.tax`} size={"100px"} data={TAX} value={0} required/></TableCell>
                                    <TableCell align="left">
                                        Nrs.<div id={`item.${index}`}>
                                            {calculateAmount(
                                                parseInt(getValues(`item.${index}.quantity`)),
                                                parseFloat(getValues(`item.${index}.rate`)),
                                                parseFloat(getValues(`item.${index}.discount`)),
                                                parseFloat(getValues(`item.${index}.tax`))).total
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">
                                        <CloseIcon onClick={()=>removeOrderItem(index)} height="12px" sx={{color:"red", padding:"10px", cursor:"pointer"}} />
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <Button onClick={()=>append({})}>Add Item</Button>

                        <LabelTextarea register={register} name={"invoiceFooter"} label={"Footer Message"} />

                        <Box sx={{background:"var(--light-brown)", padding:"16px", borderRadius:"5px", display:"flex", flexDirection:"column", gap:"10px"}}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <SubTitle title={"Sub Total"} />
                                <SubTitle title={"Nrs."+(subTotal)} />
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <SubTitle title={"Total Discount"} />
                                <SubTitle title={"Nrs."+(totalDiscount)} />
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <SubTitle title={"Total Tax"} />
                                <SubTitle title={"Nrs."+(totalTax)} />
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <SubTitle title={"Total"} />
                                <SubTitle title={"Nrs."+(total)} />
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box sx={{display:"flex", gap:"10px"}}>
                                    <LabelInput register={register} name={"adjustmentTitle"} type={"text"} value={"Adjustment"} required />
                                    <LabelInput min={"negative"} register={register} name={"adjustmentAmount"} type={"decimal"} value={0} required />
                                </Box>
                                <SubTitle title={adj != null ?adj:data[1]?.data?.adjustmentAmount} />
                            </Box>
                            <Box height={"1px"} borderBottom={"1px solid var(--dark-brown)"}></Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Title title={"Total"} />
                                <Title title={"Nrs."+(parseFloat(total)+parseFloat(adj != null ?adj:data[1]?.data?.adjustmentAmount))} />
                            </Box>
                        </Box>

                        <Box position={"fixed"} right={"16px"} bottom={"16px"}>
                            <SubmitButton label={"Update Order/Invoice"}/>
                        </Box>
                    </Box>
                </form>
            }
        </Layout>
    )
}
export default EditOrder;