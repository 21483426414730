import { Box } from "@mui/material";
import { SubTitle } from "./SubTitle";

const LabelSelect = ({label,required,name,register,data,size,onSelectChange,hasAllField}) => {

    const style = {
        border:"1px solid lightgrey", 
        padding:"5px",
        borderRadius:"5px",
        width:size?size:"262px",
        ':focus': {
            border: '2px solid red!important'
        }
      };

    return (
        <Box display={"flex"} gap={"5px"}>
            {
                label &&
                <Box width={"120px"} height={"auto"} alignContent={"center"}>
                    <SubTitle title={label+(required?"*":"")} />
                </Box>
            }
            {
                register ?
                <select {...register(name)} style={style} required>
                    <option key={"z"} disabled selected value="-"> -- select an option -- </option>
                    {
                        hasAllField &&
                        <option key={"ALL"} selected value="all">ALL</option>

                    }
                    {
                        data?.map((item)=>(
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))
                    }
                </select>
                :
                <select style={style} required onChange={(e)=>onSelectChange(e.target.value)}>
                    <option key={"z"} disabled selected value=""> -- select an option -- </option>
                    <option key={"ALL"} selected value="ALL">ALL</option>
                    {
                        data?.map((item)=>(
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))
                    }
                </select>
            }
        </Box>
    )
}

export default LabelSelect;