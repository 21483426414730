import { Box } from "@mui/material";
import Layout from "../../components/Layout";
import { Title } from "../../components/Title";
import { Link } from "react-router-dom";
import Counter from "../../components/Counter";
import StorefrontIcon from '@mui/icons-material/Storefront';

const Frontend = () => {
    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <StorefrontIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Store Settings"}/>
                </Box>
            </Box>
            <Box sx={{display:"flex", gap:"15px"}}>
                {
                    localStorage.getItem("userType") === "ADMIN" &&
                    <>
                        <Link to={"/store/company-edit"}>
                            <Counter
                                bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                                count={"Company"}
                                label={"Edit Details"}
                            />
                        </Link>
                        <Link to={"/store/banner"}>
                            <Counter
                                bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                                count={"Banner"}
                                label={"Edit Banner"}
                            />
                        </Link>
                        <Link to={"/store/feature"}>
                            <Counter
                                bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                                count={"Feature"}
                                label={"Edit Feature"}
                            />
                        </Link>
                        <Link to={"/store/gallery"}>
                            <Counter
                                bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                                count={"Gallery"}
                                label={"Edit Gallery"}
                            />
                        </Link>
                        <Link to={"/store/queries"}>
                            <Counter
                                bg={`var(--light-brown)`} color={"var(--dark-brown)"}
                                count={"Queries"}
                                label={"View Queries"}
                            />
                        </Link>
                    </>
                }
            </Box>
        </Layout>
    )
}
export default Frontend;