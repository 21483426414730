import { Box } from "@mui/material";
import Layout from "../../../components/Layout";
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import StoreIcon from '@mui/icons-material/Store';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LabelInput from "../../../components/LabelInput";
import LabelSelect from "../../../components/LabelSelect";
import SubmitButton from "../../../components/SubmitButton";
import { STATUS } from "../../../constants/CONSTANTS";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect, useState } from "react";
import usePostPut from "../../../components/api/usePostPut";
import { useGetApi } from "../../../components/api/useGetApi";

const AddInventoryToBranch = () => {

    const endpoints = [
        '/api/branches/?status=ACTIVE',
        '/api/inventories/',
    ]
    
    const {data,refetch} = useGetChainApi(endpoints);

    useEffect(()=>{
        refetch();
    },[])

    const [groupId,setGroupId] = useState(0);
    const itemEndPoint = `inventory-item/?group=${groupId}`
    const {data:itemData,refetch:fetch} = useGetApi(itemEndPoint)

    const endPoint = "branch-inventory/"; 
    const imageName = null; 
    const successMessage = "Inventory Assigned!"; 
    const redirection = "/branch"; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        watch,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    useEffect(() => {
      const subscription = watch((value, { name, type }) =>
      {
          if(type === 'change' && name === "inventoryGroup"){
            setGroupId(parseInt(value.inventoryGroup))
            setValue('inventoryItem','-')
          } 
      }
      )
      return () => subscription.unsubscribe()
    }, [watch])    

    useEffect(()=>{
        if(groupId != 0){
            fetch()
        }
    },[groupId])



    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/branch/">
                    <Box display={"flex"} gap={"5px"}>
                        <StoreIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Branch"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Add Branch Inventory"}/>
                </Box>
            </Box>
            <form onSubmit={handleSubmit(onFinalSubmit)}>
            <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                    <Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Assign Inventory"} />
                            {
                                data?.length > 1 &&
                                Array.isArray(data[0]?.data) &&
                                <>
                                    <LabelSelect register={register}  name="branch" label="Branch" data={data[0]?.data?.map((item)=>({
                                        label:item.companyName,
                                        value:item.id
                                    }))} required/>
                                    <LabelSelect register={register} name="inventoryGroup" label="Inventory Group" data={data[1]?.data?.map((item)=>({
                                        label:item.title,
                                        value:item.id
                                    }))} required/>
                                </>
                            }
                            {
                                Array.isArray(itemData?.data) &&
                                <LabelSelect register={register} name="inventoryItem" label="Inventory Item" data={itemData?.data?.map((item)=>({
                                    label:"Total"+item.quantity+":"+item.title,
                                    value:item.id
                                }))} required/>
                            }
                            <LabelInput register={register} type="number" name="quantity" label={"Assigned Quantity"} required/>
                            <LabelSelect register={register} name="inventoryStatus" label="Inventory Status" data={STATUS} required/>
                        </Box>
                    </Box>
                </Box>
                <SubmitButton />
            </form>
        </Layout>
    )
}

export default AddInventoryToBranch;