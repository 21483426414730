import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { Link } from "react-router-dom";
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect } from "react";
import InventoryGroupListComponent from "./InventoryGroupListComponent";

const InventoryList =()=> {

    const endPoints = [`/api/inventories/`];
    const {data, refetch:fetch} = useGetChainApi(endPoints);

    useEffect(()=>{
        fetch()
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Inventory"}/>
                </Box>
                {
                    localStorage.getItem("userType") === "ADMIN" && 
                    <Link to="/inventory/add">
                        <Box display={"flex"} gap={"5px"} sx={{cursor:"pointer"}}>
                            <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Add Inventory"}/>
                        </Box>
                    </Link>
                }
            </Box>
            {
                data?.length > 0 &&
                <InventoryGroupListComponent data={data[0]?.data} />
            }
        </Layout>
    )
}

export default InventoryList;