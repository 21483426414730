import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import Pagination from "../../../components/Pagination";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import Tag from "../../../components/Tag";
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect, useState } from "react";
import LinkList from "./LinkList";

const AffiliateLinksList = ({userId}) =>{

    
    const [conversions, setConversions] = useState(true);

    

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <LeakAddIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Affiliate Links"}/>
                    <Tag onClick={()=> setConversions(false)} bg={"var(--light-purple)"} color={"var(--dark-purple)"} tag={"All"}/>
                    <Tag onClick={()=> setConversions(true)} bg={"var(--light-green)"} color={"var(--dark-green)"} tag={"That has conversions"}/>
                </Box>
            </Box>

            <LinkList conversions={conversions} />
            
        </Layout>
    )
}

export default AffiliateLinksList;