import { Box } from "@mui/material"

const ModelCard = ({children}) => {
    
    return (
        <Box className="modelCard">
            {children}
        </Box>
    )
}
export default ModelCard