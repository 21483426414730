import { useMutation } from "react-query";
import { authorizationAxios } from "../../axios/authorizationAxios";

export const useDeleteApi = (endpoint) => {

  async function deleteApi() {
    const res = await authorizationAxios.delete(
      `/api/${endpoint}`
    );
    return res;
  }

  const deleteApiMutation = useMutation(deleteApi);

  return { deleteApiMutation };
};