import { Box } from "@mui/material";
import Layout from "../../../components/Layout";
import { Title } from "../../../components/Title";
import LabelInput from "../../../components/LabelInput";
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import { Link } from "react-router-dom";
import LabelTextarea from "../../../components/LabelTextarea";
import FileInput from "../../../components/FileInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const AddBrand = () => {


    const endPoint = "brands/"; 
    const imageName = "logo"; 
    const successMessage = "Brand Added!"; 
    const redirection = "/brand"; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);
    
    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/brand/">
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Brand"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Add Brand"}/>
                </Box>
            </Box>
            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Title title={"Brand Details"} />
                        <LabelInput register={register} name="name" type={"text"} label="Brand Name" required />
                        <LabelInput register={register} name="color" type={"text"} label="Brand Color" required />
                        <LabelTextarea register={register} name="description" label="Description" required />
                    </Box>
                    <Box>
                        <Title title={"Logo"} />
                        <Box display={"flex"}>
                            <FileInput 
                                uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                                uploadPhotoFile={uploadPhotoFile}
                                uploadPhotoRef={uploadPhotoRef}
                                uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                            />
                        </Box>
                    </Box>
                </Box>
                <SubmitButton />
            </form>
        </Layout>
    )
}
export default AddBrand;