import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { Link } from "react-router-dom";
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LabelInput from "../../../components/LabelInput";
import FileInput from "../../../components/FileInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";

const AddCategory =() => {

    const endPoint = "categories/"; 
    const imageName = "icon"; 
    const successMessage = "Category Added!"; 
    const redirection = "/category"; 
    const type = "post";
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type);

    return (
        <Layout>
           <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/category/">
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricBikeIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Category"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricBikeIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Add Category"}/>
                </Box>
            </Box>

            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Title title={"Category Details"} />
                        <LabelInput register={register} name="name" type={"text"} label="Category Name" required />
                    </Box>
                    <Box>
                        <Title title={"Icon"} />
                        <FileInput 
                            uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                            uploadPhotoFile={uploadPhotoFile}
                            uploadPhotoRef={uploadPhotoRef}
                            uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                        />
                    </Box>
                </Box>
                <SubmitButton />
            </form>
        </Layout>
    )
}

export default AddCategory;