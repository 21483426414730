import { Box } from "@mui/material"

const Counter = ({color, bg, count, label}) => {
    return (
        <Box sx={{width:"10rem", background:bg, color:{color}, padding:"10px", borderRadius:"5px"}}>
            <Box fontSize={"2rem"}>
                {count}
            </Box>
            {label}
        </Box>
    )
}

export default Counter;