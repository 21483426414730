import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Layout from "../../../components/Layout";
import { Title } from "../../../components/Title";
import Counter from "../../../components/Counter";
import PaymentsIcon from '@mui/icons-material/Payments';
import { useEffect, useState } from "react";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";

const PaymentList = () => {
    const [page,setPage] = useState(1);
    const endPints = [
        `/api/payments/?page=${page}`
    ]
    const {data, refetch,isLoading} = useGetChainApi(endPints);

    useEffect(()=>{
        refetch();
    },[page])

    const navigate = useNavigate();

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <PaymentsIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Payments"}/>
                </Box>
            </Box>

            <Box display={"flex"} gap={"10px"}>
                <Counter bg={`var(--light-brown)`} color={"var(--dark-brown)"} count={data?.length > 0 ? data[0]?.data?.results?.weekly_payments :""} label={"Payments This Week"} />
                <Counter bg={`var(--light-purple)`} color={"var(--dark-purple)"} count={data?.length > 0 ? data[0]?.data?.results?.monthly_payments :""} label={"Payments This Month"} />
            </Box>
            {
                data?.length > 0 &&
                <Pagination title={"Recent Payments"} setPage={setPage} next={ data[0]?.data?.next} previous={ data[0]?.data?.previous}/>
            }
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Date</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Invoice#</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Order#</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Amount</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Payment Type</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Remark</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {   
                        data?.length > 0 &&
                        Array.isArray(data[0]?.data?.results?.results) &&
                        data[0]?.data?.results?.results?.map((item)=>(
                        <TableRow 
                            onClick={()=>navigate(`/order/detail/${item.order}`)} 
                            key={item.id} sx={{cursor:"pointer", '&:last-child td, &:last-child th': { border: 0 }}} >
                                <TableCell align="left">{item.date}</TableCell>                            
                                <TableCell align="left">{item.order}</TableCell>                            
                                <TableCell align="left">{item.order}</TableCell>                            
                                <TableCell align="left">{item.amount}</TableCell>                            
                                <TableCell align="left">{item.paymentType}</TableCell>                            
                                <TableCell align="left">{item.remark}</TableCell>                            
                        </TableRow>
                        ))
                    }
                    {
                        isLoading &&
                        <Box>
                            Loading
                        </Box>
                    }
                    
                </TableBody>
            </Table>
        </Layout>
    )
}
export default PaymentList;