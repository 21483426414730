import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import LabelInput from "../../../components/LabelInput";
import FileInput from "../../../components/FileInput";
import usePostPut from "../../../components/api/usePostPut";
import { useEffect } from "react";
import { useGetApi } from "../../../components/api/useGetApi";
import SubmitButton from "../../../components/SubmitButton";
import LabelTextarea from "../../../components/LabelTextarea";

const InvoiceSettingEdit = () => {

    const endPoint = 'invoice-setting/1/';
    const {data,refetch} = useGetApi(endPoint);

    useEffect(()=>{
        refetch();
    },[])

    const imageName = "invoiceLogo";
    const successMessage = "Setting Updated!"; 
    const redirection = `/invoice-setting/`; 
    const type = "put";
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type);

    return (
        <Layout>
            <Box display={"flex"} justifyContent={"space-between"} sx={{borderBottom:"1px solid lightgray"}}>
                <Box sx={{display:"flex",paddingBottom:"5px", gap:"5px"}}>
                    <Link to="/invoice-setting">
                        <Box display={"flex"} gap={"5px"}>
                            <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Invoice Setting"}/>
                        </Box>
                    </Link>
                    <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Edit Invoice Setting"}/>
                    </Box>
                </Box>
            </Box>
            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                    <Title title={"Invoice Setting"} />
                    <FileInput 
                        uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                        uploadPhotoFile={uploadPhotoFile}
                        uploadPhotoRef={uploadPhotoRef}
                        uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                        image={data?.data?.invoiceLogo}
                    />
                    <LabelInput register={register} value={data?.data?.invoiceFrom} name={"invoiceFrom"} label={"Company Name"} />
                    <LabelInput register={register} value={data?.data?.invoiceFromAddress} name={"invoiceFromAddress"} label={"Company Address"} />
                    <LabelInput register={register} value={data?.data?.invoiceFromEmail} name={"invoiceFromEmail"} label={"Company Email"} />
                    <LabelInput register={register} value={data?.data?.invoiceFromPhone} name={"invoiceFromPhone"} label={"Company Phone"} />
                    <LabelInput register={register} value={data?.data?.invoiceFromWeb} name={"invoiceFromWeb"} label={"Company Web"} />
                    <LabelTextarea register={register} value={data?.data?.invoiceFooter} name={"invoiceFooter"} label={"Footer Message"} />
                    <SubmitButton />
                </Box>  
            </form>
        </Layout>
    )
}

export default InvoiceSettingEdit;