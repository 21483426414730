import { Box, Tab, Tabs } from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import Person4Icon from '@mui/icons-material/Person4';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Link, useParams } from "react-router-dom";
import Tag from "../../../components/Tag";
import LeftLabelText from "../../../components/LeftLabelText";
import Counter from "../../../components/Counter";
import { useEffect, useState } from "react";
import { useGetApi } from "../../../components/api/useGetApi";
import CustomTabPanel from "../../../components/CustomTabPanel";
import EditButton from "../../../components/EditButton";
import TopLabelText from "../../../components/TopLabelText";
import Zoom from 'react-medium-image-zoom'
import LinkList from "../../affiliate-links/list/LinkList";

const UserDetail = () => {


    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
      }

    const params = useParams();
    const endPoint = `users/?id=${params?.id}&type=BRANCH_AND_ADMIN`;
    const {data, refetch:fetch} = useGetApi(endPoint);

    useEffect(()=>{
        fetch();
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/user/">
                    <Box display={"flex"} gap={"5px"}>
                        <Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Branch User"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"User Detail"}/>
                </Box>
            </Box>

            <Box sx={{display:"flex", flexDirection:"column", gap:"5px"}}>
                <Box>
                    <Zoom>
                        <img alt="li ha user image" src={data?.data[0]?.profile?.profilePicture} style={{width:"6rem", height:"6rem", objectFit:"cover", border:"1px solid brown", borderRadius:"5px"}} />
                    </Zoom>
                    <Title title={data?.data[0]?.first_name + " " + data?.data[0]?.last_name} />
                    <Box display={"flex"} gap={"8px"}>
                        <Tag tag={data?.data[0]?.profile?.isVerified === true ? "Verified" : "Not Verified"} 
                            bg={data?.data[0]?.profile?.isVerified === true ? "var(--light-green)":"var(--light-brown)"  } 
                            color={data?.data[0]?.profile?.isVerified === true ? "var(--dark-green)":"var(--dark-brown)"}/>
                        <Tag tag={data?.data[0]?.profile?.status} bg={"var(--light-brown)"} color={"var(--dark-brown)"}/>
                        <Tag tag={data?.data[0]?.profile?.userType} bg={"var(--light-brown)"} color={"var(--dark-brown)"}/>
                    </Box>
                </Box>
                <LeftLabelText label={"Branch"} value={data?.data[0]?.profile?.branch?.companyName}/>

                <Box display={"flex"} gap={"10px"}>
                    <Counter bg={`var(--light-brown)`} color={"var(--dark-brown)"} count={data?.data[0]?.link_count} label={"Total Link Shared"} />
                </Box>
            </Box>     
            <Box sx={{borderBottom:"1px solid lightgray", marginTop:"16px"}}></Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="tabs" indicatorColor={'secondary'}>
                        <Tab label="User Details" {...a11yProps(0)} />
                        <Tab label="Shared Links" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {
                        localStorage.getItem("userType") === "ADMIN" &&
                        <EditButton/>
                    }
                    
                    <Box display={"flex"} flexWrap={"wrap"} gap={"15px"}>
                        <Box>
                            <Title title={"Personal Details"} />
                            <TopLabelText label="DOB" value={data?.data[0]?.profile?.dob}/>
                            <TopLabelText label="DOB" value={data?.data[0]?.profile?.gender}/>
                        </Box>
                        <Box>
                            <Title title={"Contact Details"} />
                            <TopLabelText label="Phone" value={data?.data[0]?.profile?.phone_ext + " " +data?.data[0]?.profile?.phone}/>
                            <TopLabelText label="Email" value={data?.data[0]?.email}/>
                        </Box>

                        <Box>
                            <Title title={"Address"} />
                            <TopLabelText label="Street" value={data?.data[0]?.profile?.street}/>
                            <TopLabelText label="City" value={data?.data[0]?.profile?.city}/>
                            <TopLabelText label="State" value={data?.data[0]?.profile?.province}/>
                            <TopLabelText label="Postal Code" value={data?.data[0]?.profile?.postalCode}/>
                            <TopLabelText label="Country" value={data?.data[0]?.profile?.country}/>
                            
                        </Box>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <LinkList userId={params?.id} conversions={"all"} />
                </CustomTabPanel>
        </Layout>
    )
}

export default UserDetail;