import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { Link, useNavigate } from "react-router-dom";
import BusinessIcon from '@mui/icons-material/Business';
import { useEffect, useState } from "react";
import { useGetApi } from "../../../components/api/useGetApi";
import { STATUS } from "../../../constants/CONSTANTS";
import Tag from "../../../components/Tag";

const VendorList = () => {
    const navigate = useNavigate();
    const [status,setStatus] = useState("ALL");
    const endPoint = `vendors/?status=${status}`;
    const {data,refetch:fetch} = useGetApi(endPoint);

    useEffect(()=>{
        fetch();
    },[status])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Vendor"}/>
                    <Tag onClick={()=>setStatus("ALL")} bg={"var(--light-blue)"} color={"var(--dark-blue)"} tag={"ALL"}/>
                    <Tag onClick={()=>setStatus(STATUS[1].value)} bg={"var(--light-green)"} color={"var(--dark-green)"} tag={STATUS[1].label}/>
                    <Tag onClick={()=>setStatus(STATUS[0].value)} bg={"var(--light-brown)"} color={"var(--dark-brown)"} tag={STATUS[0].label}/>
                    <Tag onClick={()=>setStatus(STATUS[2].value)} bg={"var(--light-purple)"} color={"var(--dark-purple)"} tag={STATUS[2].label}/>
                </Box>
                <Link to="/vendor/add">
                    <Box display={"flex"} gap={"5px"} sx={{cursor:"pointer"}}>
                        <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Add Vendor"}/>
                    </Box>
                </Link>
            </Box>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Company Name</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Primary Phone</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Primary Email</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Address</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Status</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.isArray(data?.data) && 
                        data?.data?.map((item)=>(
                            <TableRow onClick={()=>navigate(`/vendor/detail/${item.id}`)} key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor:"pointer"}} >
                                <TableCell component="th" scope="row">{item.companyName}</TableCell>
                                <TableCell align="left">{item.primaryPhone}</TableCell>
                                <TableCell align="left">{item.primaryEmail}</TableCell>
                                <TableCell align="left">{item.street + ", " + item.city + ", "+item.country}</TableCell>
                                <TableCell align="left">{item.vendorStatus}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Layout>
    )
}

export default VendorList;