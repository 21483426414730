import { Box } from "@mui/material"
import { SubTitle } from "./SubTitle";

const TopLabelText = ({label, value}) => {
    return (
        <Box display={"flex"} flexDirection={"column"} gap={"5px"} width={"200px"} height={"auto"} alignContent={"center"}>
            <SubTitle title={label} bold={true}/>
            <SubTitle title={value} />
        </Box>
    )
}

export default TopLabelText;