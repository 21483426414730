import { Box } from "@mui/material";
import { Title } from "../../components/Title";
import LabelInput from "../../components/LabelInput";
import SubmitButton from "../../components/SubmitButton";
import { SubTitle } from "../../components/SubTitle";
import usePostLogin from "./usePostLogin";

const Login = () => {

    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
      } = usePostLogin();
    
    return (
        <Box sx={{background:"var(--secondary-color)",height:"100vh", width:"100vw", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box sx={{background:"white", height:"auto", width:"500px", padding:"16px", borderRadius:"5px", display:"flex", flexDirection:"column", gap:"10px"}}>
                    <Title title={"Admin Login"} />
                        <LabelInput type={"email"} register={register} name="email" label={"Email"} required />
                        <LabelInput type={"password"} register={register} name="password" label={"Password"} required />
                        <SubmitButton label={"Login"} />
                    <SubTitle title={"Forgot Password?"} />
                </Box>
            </form>
        </Box>
    )
}

export default Login;