import { useMutation } from "react-query";
import { axiosNoAuth } from "../../../axios/axiosNoAuth";

export default function usePostLoginApi(){
    async function postNewLogin(values) {
        let res = await axiosNoAuth.post(
          `/api/login/`,
          values
        );
        return res;
      }
    
      const postNewLoginMutation = useMutation(postNewLogin);
    
      return {
        postNewLoginMutation,
      };
}