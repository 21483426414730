// AppContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [expand, setExpand] = useState(() => {
    // Initialize state from localStorage or use default initial state
    const savedState = localStorage.getItem('appState');
    return savedState ? JSON.parse(savedState) : true;
  });

  useEffect(() => {
    // Save state to localStorage whenever it changes
    localStorage.setItem('appState', JSON.stringify(expand));
  }, [expand]);

  return (
    <AppContext.Provider value={{ expand, setExpand }}>
      {children}
    </AppContext.Provider>
  );
};
