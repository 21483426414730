import { Box, Modal, Tooltip, Typography} from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link, useParams } from "react-router-dom";
import Invoice from "../components/Invoice";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect, useState } from "react";
import ModelCard from "../../../components/ModelCard";
import { ORDER_STATUS, PAYMENT_METHOD, SHIPMENT_STATUS } from "../../../constants/CONSTANTS";
import LabelSelect from "../../../components/LabelSelect";
import LabelInput from "../../../components/LabelInput";
import LabelTextarea from "../../../components/LabelTextarea";
import SubmitButton from "../../../components/SubmitButton";
import FileInput from "../../../components/FileInput";
import usePostPut from "../../../components/api/usePostPut";
import LeftLabelText from "../../../components/LeftLabelText";
import { SubTitle } from "../../../components/SubTitle";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDeleteApi } from "../../../components/api/useDeleteApi";
import HistoryIcon from '@mui/icons-material/History';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const OrderDetail = () => {

    const params = useParams();
    const endPoints = [`/api/order/${params.id}/`]
    const {data, refetch} = useGetChainApi(endPoints)

    useEffect(()=>{
        refetch()
    },[])

    return (
        <Layout>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                    <Link to="/order/">
                        <Box display={"flex"} gap={"5px"}>
                            <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Order"}/>
                        </Box>
                    </Link>
                    <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Order Detail"}/>
                    </Box>
                </Box>
                <Box display={"flex"} gap={"10px"}>
                    <Link to={`/order/detail/attachments/${params.id}`}>
                        <Box display={"flex"} gap={"5px"}>
                            <AttachFileIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Attachments"}/>
                        </Box>
                    </Link>
                    {
                        localStorage.getItem("userType") === "ADMIN" &&
                        <Link to={`/order/detail/audit/${params.id}`}>
                            <Box display={"flex"} gap={"5px"}>
                                <HistoryIcon height="18px" sx={{color:"var(--primary-color)"}} />
                                <Title title={"Audit"}/>
                            </Box>
                        </Link>
                    }
                    
                </Box>
            </Box>

            <Box sx={{display:"flex", flexWrap:"wrap", gap:'20px'}}>
                {
                    data?.length > 0 &&
                    <Invoice data={data[0].data} /> 
                }

                {
                    data?.length > 0 &&
                    <Box className="flexColumn" gap={"16px"} flexGrow={1} maxWidth={"300px"}>
                        {/* invoiced by */}
                        <Box className="boxShadow" p={"10px"}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Title title={"Invoiced By"}/>
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Title title={"Branch"}/>
                                <Link to={`/branch/detail/${data[0]?.data?.branch?.id}`}>
                                    <SubTitle title={data[0]?.data?.branch?.name}/>
                                </Link>
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Title title={"Address"}/>
                                <SubTitle title={data[0]?.data?.branch?.address}/>
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Title title={"User"}/>
                                <Link to={`/user/detail/${data[0]?.data?.salesPerson?.id}`}>
                                    <SubTitle title={data[0]?.data?.salesPerson?.name}/>
                                </Link>
                            </Box>
                        </Box>
                        {
                            data[0]?.data?.affiliateLink &&

                            <Box className="boxShadow" p={"10px"}>
                                <Link to={`/affiliate-links/detail/${data[0]?.data?.affiliateLink?.id}`}>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Title title={"Affiliate Conversion"}/>
                                        <u>
                                            <SubTitle title={data[0]?.data?.affiliateLink?.id}/>
                                        </u>
                                    </Box>
                                </Link>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Title title={"Marketer"}/>
                                    <SubTitle title={data[0]?.data?.affiliateLink?.user}/>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Title title={"Commission"}/>
                                    <SubTitle title={data[0]?.data?.conversion?.commission}/>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Title title={"is Verified?"}/>
                                    <SubTitle title={data[0]?.data?.conversion?.isVerified ? "Verified" : "Not Verified"}/>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Title title={"is Paid?"}/>
                                    <SubTitle title={data[0]?.data?.conversion?.isPaid ? "Paid" : "Not Paid"}/>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Title title={"is Discarded?"}/>
                                    <SubTitle title={data[0]?.data?.conversion?.isDiscarded ? "Discarded" : "Not Discarded"}/>
                                </Box>
                            </Box>
                        }
                        
                        <OrderStatus data={data} />

                        <Remark data={data}/>
                        
                        <Box className="boxShadow" p={"10px"}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Title title={"Payments"}/>
                                <AddPaymentCard />
                            </Box>
                            {
                                data[0]?.data?.payments?.map((item)=>(
                                    <Tooltip key={item.id} title={"Remark: "+item.remark} arrow placement="left" >
                                        <PaymentCard refetch={refetch} data={item} />
                                        <Box sx={{borderBottom:"1px solid var(--dark-brown)",marginTop:"4px", marginBottom:"4px"}}></Box>
                                    </Tooltip>
                                ))
                            }
                            <Box display={"flex"} justifyContent={"space-between"}>
                                    <Title title={"Total Paid"}/>
                                    <Title title={"Nrs."+data[0]?.data?.total_paid}/>
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                    <Title title={"Total Due"}/>
                                    <Title title={"Nrs."+(parseFloat(data[0]?.data?.totalAmount)-parseFloat(data[0]?.data?.total_paid))}/>
                            </Box>
                        </Box>

                        <Box className="boxShadow" p={"10px"}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Title title={"Number Plates"}/>
                                <AddNumberPlateCard data={data} />
                            </Box>
                            {
                                data[0]?.data?.numberplates?.map((item)=>(
                                    <Tooltip key={item.id} title={"Remark: "+item.remark} arrow placement="left" >
                                        <NumberPlateCard refetch={refetch} data={item} orderItem={data[0]?.data?.items}/>
                                        <Box sx={{borderBottom:"1px solid var(--dark-brown)",marginTop:"4px", marginBottom:"4px"}}></Box>
                                    </Tooltip>
                                ))
                            }
                        </Box>

                    </Box>
                }
            </Box>
        </Layout>
    )
}

const PaymentCard = ({data, refetch}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const params = useParams();
    const endPoint = `payments/${data?.id}/`; 
    const imageName = "paymentImage"; 
    const successMessage = "Payment Added!"; 
    const redirection = `/order/detail/${params.id}`; 
    const type = "put";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    let endpoint = `payments/${data?.id}/`
    const {deleteApiMutation} = useDeleteApi(endpoint);

    useEffect(()=>{
        refetch();
        setOpen(false);
    },[deleteApiMutation?.isSuccess])

    useEffect(()=>{
        if(data){
            setValue('paymentType', data.paymentType)
        }
    },[data])

    return (
        <>
            <Box onClick={handleOpen} sx={{display:"flex", justifyContent:"space-between", alignItems:"center", cursor:"pointer"}}>
                <Box>
                    <span className="invoiceHeading">{data?.paymentType} <ModeEditIcon sx={{fontSize:"16px", marginRight:"5px"}} /></span><br></br>
                    <span className="invoiceText">{data?.date?.split("T")[0]}-{data?.transactionCode?data?.transactionCode:"NO CODE"}</span>
                </Box>
                <Box>
                    <span className="invoiceText" style={{textAlign:"left"}}>Nrs. {data?.amount}</span>
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <ModelCard>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Title title={"Update Payment"} />
                            <Box sx={{cursor:"pointer"}} onClick={() =>deleteApiMutation.mutate()} >
                                <DeleteForeverIcon height="12px" sx={{color:"red"}} />
                            </Box>
                        </Box>
                        <input type="hidden" {...register("order")} value={params.id} />
                        <LabelSelect register={register} name={"paymentType"} label="Payment Method" data={PAYMENT_METHOD} required />
                        <LabelInput value={data.amount} register={register} name={"amount"} label="Amount" type="number" required/>
                        <LabelInput register={register} value={data.date?.split("T")[0]} name="date" label="Paid Date" type="date" required/>
                        <LabelTextarea value={data.remark} register={register} name={"remark"} label={"Remark"} required />
                        <FileInput 
                            uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                            uploadPhotoFile={uploadPhotoFile}
                            uploadPhotoRef={uploadPhotoRef}
                            uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                            image={data.paymentImage}
                        />
                        <SubmitButton label={"Update Payment"} />
                    </ModelCard>
                </form>
            </Modal>
        </>
    )
}

const AddPaymentCard = () =>{
    const params = useParams();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const endPoint = "payments/"; 
    const imageName = "paymentImage"; 
    const successMessage = "Payment Added!"; 
    const redirection = `/order/detail/${params.id}`; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <>
            <Box sx={{cursor:"pointer"}} onClick={handleOpen}>
                <Title title={"Add Payment"} element={<ReceiptIcon sx={{fontSize:"16px",color:"var(--primary-color)"}} />}/>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <ModelCard>
                        <Title title={"Add Payment"} />
                        <input type="hidden" {...register("order")} value={params.id} />
                        <LabelSelect register={register} name={"paymentType"} label="Payment Method" data={PAYMENT_METHOD} required />
                        <LabelInput register={register} name={"amount"} label="Amount" type="number" required/>
                        <LabelInput register={register} name="date" label="Paid Date" type="date" required/>
                        <LabelTextarea register={register} name={"remark"} label={"Remark"} required />
                        <FileInput 
                            uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                            uploadPhotoFile={uploadPhotoFile}
                            uploadPhotoRef={uploadPhotoRef}
                            uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                        />
                        <SubmitButton label={"Record Payment"} />
                    </ModelCard>
                </form>
            </Modal>
        </>
    )
}

const Remark =({data}) => {
    const params = useParams();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const endPoint = `orders/${data[0]?.data?.id}/`; 
    const imageName = "paymentImage"; 
    const successMessage = "Remark Updated!"; 
    const redirection = `/order/detail/${params.id}`; 
    const type = "put";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <>
            <Box className="boxShadow" sx={{cursor:"pointer"}} p={"10px"} onClick={handleOpen}>
                <Title title={"Remarks"} element={<ModeEditIcon sx={{fontSize:"16px", marginRight:"5px"}} />}/>
                <span className="invoiceText">{data[0]?.data?.remark}</span>

            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <ModelCard>
                        <Title title={"Remarks"} />
                        <Box fontStyle={"italic"} fontSize={"13px"} >
                            IMPORTANT: Remarks are for admins and branch users only. Customers cannot see Remarks.
                        </Box>
                        <LabelTextarea register={register} name={"remark"} value={data[0]?.data?.remark} />
                        <SubmitButton />
                    </ModelCard>
                </form>
            </Modal>
        </>
    )
}

const OrderStatus = ({data}) => {
    const params = useParams();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const endPoint = `orders/${data[0]?.data?.id}/`; 
    const imageName = "paymentImage"; 
    const successMessage = "Payment Added!"; 
    const redirection = `/order/detail/${params.id}`; 
    const type = "put";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    useEffect(()=>{
        setValue('status', data[0]?.data?.status)
        setValue('shipmentStatus', data[0]?.data?.shipmentStatus)
    },[data])

    return (
        <>
            <Box className="boxShadow" sx={{cursor:"pointer"}} p={"10px"} onClick={handleOpen}>
                <Title title={"Order Status"} element={<ModeEditIcon sx={{fontSize:"16px", marginRight:"5px"}} />}/>
                <LeftLabelText label={"Status"} value={data[0]?.data?.status} />
                <LeftLabelText label={"Shipment Status"} value={data[0]?.data?.shipmentStatus} />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <ModelCard>
                        <Title title={"Order Status"} />
                        <LabelSelect register={register} name={"status"} label={"Invoice Status"} data={ORDER_STATUS} required />
                        <LabelSelect register={register} name={"shipmentStatus"} label={"SHIPMENT Status"} data={SHIPMENT_STATUS} required />
                        <SubmitButton />
                    </ModelCard>
                </form>
            </Modal>
        </>
    )
}

const AddNumberPlateCard = ({data}) => {
    const params = useParams();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const endPoint = "order-item-number-plate/"; 
    const imageName = null; 
    const successMessage = "Number Plate Added!"; 
    const redirection = `/order/detail/${params.id}`; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        watch,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        if(type === "change" && name === "orderitem"){
            setValue("title",data[0]?.data?.items?.filter((item)=>parseInt(item.id) === parseInt(value.orderitem) )[0]?.inventoryItem?.title)
            setValue("color",data[0]?.data?.items?.filter((item)=>parseInt(item.id) === parseInt(value.orderitem) )[0]?.inventoryItem?.color)
            setValue("model",data[0]?.data?.items?.filter((item)=>parseInt(item.id) === parseInt(value.orderitem) )[0]?.inventoryItem?.model)
        }
      }
      );
      return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <>
            <Box sx={{cursor:"pointer"}} onClick={handleOpen}>
                <Title title={"Add New"} element={<ReceiptIcon sx={{fontSize:"16px",color:"var(--primary-color)"}} />}/>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <ModelCard>
                        <Title title={"Add Number Plate"} />
                        <input type="hidden" {...register("order")} value={params.id} />
                        <LabelSelect register={register} name={"orderitem"} label="Ordered Item"
                         data={
                            data[0]?.data?.items?.map((item)=>(
                                {
                                    label:item?.inventoryItem?.title,
                                    value:item.id
                                }
                            ))
                         } required />
                        <LabelInput register={register} name={"title"} label="Item Name" type="text" required/>
                        <LabelInput register={register} name={"color"} label="Color" type="text" required/>
                        <LabelInput register={register} name={"model"} label="Modal" type="text" required/>
                        <LabelInput register={register} name={"numberPlate"} label="Number Plate" type="text" required/>
                        
                        <SubmitButton label={"Add Number Plate"} />
                    </ModelCard>
                </form>
            </Modal>
        </>
    )
}

const NumberPlateCard =({orderItem,data, refetch}) =>{

    const params = useParams();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const endPoint = `order-item-number-plate/${data.id}/`; 
    const imageName = null; 
    const successMessage = "Numberplate Updated!"; 
    const redirection = `/order/detail/${params.id}`; 
    const type = "put";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        watch,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    const {deleteApiMutation} = useDeleteApi(`order-item-number-plate/${data.id}/`);
    useEffect(()=>{
        refetch();
        setOpen(false);
    },[deleteApiMutation?.isSuccess])

    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        if(type === "change" && name === "orderitem"){
            setValue("title",orderItem?.filter((item)=>parseInt(item.id) === parseInt(value.orderitem) )[0]?.inventoryItem?.title)
            setValue("color",orderItem?.filter((item)=>parseInt(item.id) === parseInt(value.orderitem) )[0]?.inventoryItem?.color)
            setValue("model",orderItem?.filter((item)=>parseInt(item.id) === parseInt(value.orderitem) )[0]?.inventoryItem?.model)
        }
      }
      );
      return () => subscription.unsubscribe();
    }, [watch]);
    
    useEffect(()=>{
        if(data){
            setValue('orderitem', data.orderitem)
        }
    },[data])

    return (
        <>
            <Box onClick={handleOpen} sx={{display:"flex", justifyContent:"space-between", alignItems:"center", cursor:"pointer"}}>
                <Box>
                    <span className="invoiceHeading">{data?.title} <ModeEditIcon sx={{fontSize:"16px", marginRight:"5px"}} /></span><br></br>
                    <span className="invoiceText">{data?.color}-{data?.model}</span>
                </Box>
                <Box>
                    <span className="invoiceText" style={{textAlign:"left"}}>{data?.numberPlate}</span>
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <ModelCard>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Title title={"Update Number Plate"} />
                            <Box sx={{cursor:"pointer"}} onClick={() =>deleteApiMutation.mutate()} >
                                <DeleteForeverIcon height="12px" sx={{color:"red"}} />
                            </Box>
                        </Box>
                        <input type="hidden" {...register("order")} value={params.id} />
                        <LabelSelect register={register} name={"orderitem"} label="Ordered Item"
                         data={
                            orderItem?.map((item)=>(
                                {
                                    label:item?.inventoryItem?.title,
                                    value:item.id
                                }
                            ))
                         } required />
                        <LabelInput register={register} name={"title"} value={data?.title} label="Item Name" type="text" required/>
                        <LabelInput register={register} name={"color"} value={data?.color} label="Color" type="text" required/>
                        <LabelInput register={register} name={"model"} value={data?.model} label="Modal" type="text" required/>
                        <LabelInput register={register} name={"numberPlate"} value={data?.numberPlate} label="Number Plate" type="text" required/>
                        
                        <SubmitButton label={"Update Number Plate"} />
                    </ModelCard>
                </form>
            </Modal>
        </>
    )
}

export default OrderDetail;