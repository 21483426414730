import axios from "axios";
import { BASE_URL } from "../constants/CONSTANTS";
import { axiosNoAuth } from "./axiosNoAuth";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

export const authorizationAxios = axios.create({
  baseURL: BASE_URL
});

authorizationAxios.interceptors.request.use(function (config) {
    config.headers['Authorization'] = `Bearer ${ls.get("_la")}`;
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

//response interceptor
authorizationAxios.interceptors.response.use(function (response) {
    return response;
  }, async function (error) {
    const status = error.response ? error.response.status : null;
    // Handle different status codes
    if (status === 401) {
        try {
            const newAccessToken = await getNewAccessToken();
            ls.set('_la', `${newAccessToken}`, {
                path: '/',
                // Set to true if using HTTPS
              });
              error.config.headers.Authorization = `Bearer ${newAccessToken}`;
              return axios.request(error.config);
          } catch (refreshError) {
            // If token refresh fails, reject the original request
            return Promise.reject(refreshError);
          }

    } else {
      // For other error status codes, reject the promise
      return Promise.reject(error);
    }
  });
  

  async function getNewAccessToken() {
    try {
      const refreshToken = ls.get('_lr');
      const response = await axiosNoAuth.post('/api/token/refresh/', {
        refresh: refreshToken,
      });
      const newAccessToken = response.access;
      return newAccessToken;
    } catch (error) {
        ls.removeAll();
        window.location.href = "/admin/login";
      throw new Error('Failed to refresh access token');
    }
  }