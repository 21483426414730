import { useForm } from "react-hook-form";
import usePostLoginApi from "./api/usePostLoginApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

export default function usePostLogin(){
    const { postNewLoginMutation } = usePostLoginApi();
    const { isLoading, isError, error, isSuccess, data } = postNewLoginMutation;

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

      const onFinalSubmit = (values) => {
        postNewLoginMutation.mutate(values);
      };

      useEffect(() => {
        if (isSuccess) {
            console.log(data)
            if(data?.access_token){
              ls.set('_la', data.access_token);
              ls.set('_lr', data.refresh_token);
              
              localStorage.setItem('user', JSON.stringify(data.user));
              localStorage.setItem('userType', data.userType);
              localStorage.setItem('branchId', data.branchId);
              window.location.href = "/order";
            }else{
              toast.error("Invalid email or password!", {
                position: "top-right",
                autoClose: 2000,});
            }
        }
    
        if (isError) {
            console.log("error")
        }
      }, [
        postNewLoginMutation.isSuccess,
        postNewLoginMutation.isLoading,
        postNewLoginMutation.isError,
      ]);

      return {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
      };
    
}