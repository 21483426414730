import { Box } from "@mui/material";

const CustomTabPanel =(props)=> {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{px:2}}>
            {children}
          </Box>
        )}
      </div>
    );
  }
export default CustomTabPanel;