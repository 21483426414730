import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { SubTitle } from "../../../components/SubTitle";
import { Title } from "../../../components/Title";
import EditButton from "../../../components/EditButton";

const Invoice = ({data}) =>{
    return (
        <Box className="boxShadow flexColumn" sx={{gap:"16px", width:"900px", padding:"40px 20px 40px 20px"}}>
                <EditButton/>
                <Box sx={{display:"flex", justifyContent:"space-between" }}>
                    <Box className="flexColumn">
                        <span className="invoiceHeading">{data?.invoiceToFrom}</span>
                        <span className="invoiceText">{data?.invoiceFromAddress}</span>
                        <span className="invoiceText">{data?.invoiceFromEmail}</span>
                        <span className="invoiceText">lihamotors.com</span>
                    </Box>
                    <Box className="flexColumn">
                        <span className="invoiceSubHeading">#{data?.invoiceNumber}</span>
                        <span className="invoiceSubHeading">Invoice Date</span>
                        <span className="invoiceText">{data?.orderDate?.split("T")[0]}</span>
                        <br></br>
                        <span className="invoiceSubHeading">Due Date</span>
                        <span className="invoiceText">{data?.dueDate?.split("T")[0]}</span>
                    </Box>
                </Box> 
                <Box className="flexColumn">
                    <span className="invoiceSubHeading">BILL TO</span>
                    <span className="invoiceText">{data?.invoiceToName}</span>
                    <span className="invoiceText">{data?.invoiceToAddress}</span>
                </Box>
                <Box className="flexColumn">
                    <span className="invoiceSubHeading">Subject</span>
                    <span className="invoiceText">{data?.invoiceSubject}</span>
                </Box>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead >
                    <TableRow >
                        <TableCell sx={{color:"var(--primary-color)"}} >Item Detail</TableCell>
                        <TableCell sx={{color:"var(--primary-color)"}} align="left">Quantity</TableCell>
                        <TableCell sx={{color:"var(--primary-color)"}} align="left">Rate</TableCell>
                        <TableCell sx={{color:"var(--primary-color)"}} align="left">Discount</TableCell>
                        <TableCell sx={{color:"var(--primary-color)"}} align="left">Tax</TableCell>
                        <TableCell sx={{color:"var(--primary-color)"}} align="left">Amount</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.items?.map((item)=>(
                            <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                <TableCell component="th" scope="row">
                                {item.inventoryItem.title}
                                </TableCell>
                                <TableCell align="left">{item.quantity}</TableCell>
                                <TableCell align="left">{item.rate}</TableCell>
                                <TableCell align="left">{item.discount}%</TableCell>
                                <TableCell align="left">{item.tax}%</TableCell>
                                <TableCell align="left">
                                    Nrs.{item.totalAmount}
                                </TableCell>
                            </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <Box sx={{background:"var(--light-brown)", padding:"16px", borderRadius:"5px", display:"flex", flexDirection:"column", gap:"10px"}}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <SubTitle bold={true} title={"Sub Total"} />
                        <SubTitle bold={true} title={"Nrs."+data?.orderAmount} />
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <SubTitle bold={true} title={"Total Discount"} />
                        <SubTitle bold={true} title={"Nrs."+data?.discount} />
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <SubTitle bold={true} title={"Total Tax"} />
                        <SubTitle bold={true} title={"Nrs."+data?.taxAmount} />
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <SubTitle bold={true} title={"Total"} />
                        <SubTitle bold={true} title={"Nrs."+(parseFloat(data?.orderAmount)-parseFloat(data?.discount)+parseFloat(data?.taxAmount))} />
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <SubTitle bold={true} title={"Adjustment"} />
                        <SubTitle bold={true} title={"Nrs."+data?.adjustmentAmount} />
                    </Box>
                    <Box height={"1px"} borderBottom={"1px solid var(--dark-brown)"}></Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Title title={"Total"} />
                        <Title title={"Nrs."+data?.totalAmount} />
                    </Box>
                </Box>
                <Box>
                    <Title title={"Note"} />
                    <SubTitle title={data?.invoiceToFooter} />
                </Box>
            </Box>
    )
}

export default Invoice;