import { Box } from "@mui/material";
import SubmitButton from "../../../components/SubmitButton";
import LabelInput from "../../../components/LabelInput";
import usePostPut from "../../../components/api/usePostPut";

const VerifyOtp = () => {

    const endPoint = "profile/verify-top/"; 
    const imageName = null; 
    const successMessage = "Email changed! OTP Code Verified!"; 
    const redirection =null; 
    const type = "post";//using post for update, since we are using same api for create and update
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <>
            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box>
                    <LabelInput register={register} name="otp" type={"text"} label="OTP Code" required/>
                    <SubmitButton label={"Send OTP"} />
                </Box>
            </form>
        </>
    )
}
export default VerifyOtp;