import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { usePostPutApi } from "../../../components/api/usePostPutApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function useEditOrder(endPoint,values){
    const { postPutApiMutation } = usePostPutApi(endPoint,"post");
    const { isLoading, isError, error, isSuccess, data } = postPutApiMutation;
    const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);
    const params = useParams();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
      } = useForm({
        values:{
            status:values?.length > 1 ? values[1].data?.status:"",
            shipmentStatus:values?.length > 1 ? values[1].data?.shipmentStatus:"",
            customer:values?.length > 1 ? values[1].data?.customer?.id:"",
            invoiceNumber:values?.length > 1 ? values[1].data?.invoiceNumber:"",
            orderDate:values?.length > 1 ? values[1].data?.orderDate:"",
            dueDate:values?.length > 1 ? values[1].data?.dueDate:"",
            invoiceSubject:values?.length > 1 ? values[1].data?.invoiceSubject:"",
            invoiceFooter:values?.length > 1 ? values[1].data?.invoiceToFooter:"",
            adjustmentTitle:values?.length > 1 ? values[1].data?.adjustmentTitle:"",
            adjustmentAmount:values?.length > 1 ? values[1].data?.adjustmentAmount:"",
            item : values?.length > 1 ? values[1].data?.items?.map((item)=>({
                ...item,
                inventoryItem: item.inventoryItem.id
            })) : {}
        }
      });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormProvider)
        name: "item", // unique name for your Field Array
      });

      const onFinalSubmit = (values) => {
        postPutApiMutation.mutate(values);
      };

      useEffect(() => {
        if (isSuccess) {
            toast.success("Order Updated", {
                position: "top-right",
                autoClose: 2000,});
              window.location.href = `/order/detail/${params.id}`;
        }
    
        if (isError) {
            
            toast.error(error?.response?.data?.error, {
              position: "top-right",
              autoClose: 2000,});
            toast.error(error?.request?.response, {
              position: "top-right",
              autoClose: 2000,});
          }
        }, [
          postPutApiMutation.isSuccess,
          postPutApiMutation.isLoading,
          postPutApiMutation.isError,
        ]);

        const uploadPhotoRef = useRef(null);

        function uploadPhotoOnClickHandler() {
          uploadPhotoRef.current.click();
        }

        function uploadPhotoChangeHandler(event) {
          SetUploadPhotoFile(Array.from(event.target.files));
        }

      return {
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        watch,
        setValue,
        getValues,
        fields, 
        append, 
        remove,
        control
      };
    
}