import { Box } from "@mui/material"
import { SubTitle } from "./SubTitle";

const LeftLabelText = ({label, value}) => {
    return (
        <Box display={"flex"} gap={"5px"} height={"auto"} alignContent={"center"}>
            <Box width={"120px"}>
                <SubTitle title={label} bold={true}/>
            </Box>
            <SubTitle title={value} />
        </Box>
    )
}

export default LeftLabelText;