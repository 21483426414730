import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import { Title } from "../../../components/Title";
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import { useGetApi } from "../../../components/api/useGetApi";

const BrandList = () => {

    const endPoint = `brands/`;
    const {data, refetch:fetch} = useGetApi(endPoint);
    const navigate = useNavigate();

    useEffect(()=>{
        fetch()
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Brand"}/>
                </Box>
                <Link to="/brand/add">
                    <Box display={"flex"} gap={"5px"} sx={{cursor:"pointer"}}>
                        <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Add Brand"}/>
                    </Box>
                </Link>
            </Box>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)",width:"10rem"}} >Brand Logo</TableCell>
                    <TableCell sx={{color:"var(--primary-color)",width:"15rem"}} align="left">Name</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Color</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Products Count</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.isArray(data?.data) &&
                        data?.data?.map((item)=>(
                            <TableRow onClick={()=>navigate(`/brand/detail/${item.id}`)} key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor:"pointer"}} >
                                <TableCell component="th" scope="row">
                                    <img style={{height:"50px",borderRadius:"5px"}} src={item.logo} />
                                </TableCell>
                                <TableCell align="left">{item.name}</TableCell>
                                <TableCell align="left">{item.color}</TableCell>
                                <TableCell align="left">{item.total_products}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Layout>
    )
}

export default BrandList;