import Layout from "../../../components/Layout"
import usePostPut from "../../../components/api/usePostPut";
import { Box } from "@mui/material";
import { Title } from "../../../components/Title";
import LabelInput from "../../../components/LabelInput";
import LabelSelect from "../../../components/LabelSelect";
import FileInput from "../../../components/FileInput";
import SubmitButton from "../../../components/SubmitButton";
import { COUNTRY, GENDER } from "../../../constants/CONSTANTS";
import { Link, useParams } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PersonIcon from '@mui/icons-material/Person';
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect } from "react";

const EditCustomer = () => {
    const params = useParams();
    const endPoints = [
        `/api/users/?id=${params?.id}&type=NORMAL`
    ]
    const {data,refetch} = useGetChainApi(endPoints);

    useEffect(()=>{
        refetch()
    },[])

    const endPoint = "create_update_customer_with_profile/"; 
    const imageName = "profilePicture"; 
    const successMessage = "Customer Updated!"; 
    const redirection = `/customer/detail/${params?.id}`; 
    const type = "post";//using post for update, since we are using same api for create and update
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    useEffect(()=>{
        if(data){
            setValue('gender', data[0]?.data[0]?.profile?.gender);
            setValue('country', data[0]?.data[0]?.profile?.country);
        }
    },[data])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/user/">
                    <Box display={"flex"} gap={"5px"}>
                        <PersonIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Customer"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <PersonIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Customer Detail"}/>
                </Box>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <PersonIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit"}/>
                </Box>
            </Box>
            {
                data?.length > 0 &&
                data[0]?.data[0]?.id == params.id && //updating component only after data fetched because, axios is giving me old data and i need to udpate only after new data has been fetched
                <form onSubmit={handleSubmit(onFinalSubmit)} key={params.id}>
                    <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                        <Box>
                            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                                <Title title={"User Details"} />
                                <input type="hidden" {...register("id")} value={data[0]?.data[0]?.id} />
                                <LabelInput register={register} value={data[0]?.data[0]?.first_name} name="first_name" type={"text"} label="First Name" required />
                                <LabelInput register={register} value={data[0]?.data[0]?.last_name} name="last_name" type={"text"} label="Last Name" required/>
                                <LabelSelect register={register} name="gender" label="Gender" data={GENDER} required/>
                                <LabelInput register={register} value={data[0]?.data[0]?.profile?.dob} name="dob" type={"date"} label="DOB"/>

                                <Title title={"Contact Details"} />
                                <LabelInput register={register} value={data[0]?.data[0]?.profile?.phone_ext} name="phone_ext" type={"text"} label="Phone Extension" required/>
                                <LabelInput register={register} value={data[0]?.data[0]?.profile?.phone} name="phone" type={"phone"} label="Phone" required/>
                                <LabelInput register={register} value={data[0]?.data[0]?.email} name="email" type={"email"} label="Email" required/>
                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Address*"} />
                            <LabelInput register={register} value={data[0]?.data[0]?.profile?.street} name="street" type={"text"} label="Street" required/>
                            <LabelInput register={register} value={data[0]?.data[0]?.profile?.city} name="city" type={"text"} label="City" required/>
                            <LabelInput register={register} value={data[0]?.data[0]?.profile?.province} name="province" type={"text"} label="State" required/>
                            <LabelInput register={register} value={data[0]?.data[0]?.profile?.postalCode} name="postalCode" type={"text"} label="Postal Code" required/>
                            <LabelSelect register={register} name="country" label="Country" data={COUNTRY} required/>
                        </Box>
                        <Box>
                            <Title title={"Profile Picture"} />
                            <Box display={"flex"}>
                                <FileInput 
                                    uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                                    uploadPhotoFile={uploadPhotoFile}
                                    uploadPhotoRef={uploadPhotoRef}
                                    uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                                    image={data[0]?.data[0]?.profile?.profilePicture}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <SubmitButton />
                </form>
            }
        </Layout>
    )
}

export default EditCustomer;