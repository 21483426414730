import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileInput from "../../../components/FileInput";
import usePostPut from "../../../components/api/usePostPut";
import SubmitButton from "../../../components/SubmitButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useGetApi } from "../../../components/api/useGetApi";
import { useEffect, useState } from "react";
import { useDeleteApi } from "../../../components/api/useDeleteApi";
import FilePresentIcon from '@mui/icons-material/FilePresent';

const OrderAttachments = () => {
    
    
    const params = useParams();
    const {data,refetch} = useGetApi(`order-files/?oid=${params.id}`);
    
    const endPoint = "order-files/"; 
    const imageName = "file"; 
    const successMessage = "File Uploaded!"; 
    const redirection = `/order/detail/attachments/${params.id}`; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    const [imageId, setImageId] = useState(0);
    let endpoint = `order-files/${imageId}/`
    const {deleteApiMutation} = useDeleteApi(endpoint);

    useEffect(()=>{
        refetch()
    },[deleteApiMutation?.isSuccess])

    function handleImageDelete(){
        setTimeout(()=>{
            deleteApiMutation.mutate();
        },100)
    }

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/order/">
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Order"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Link to={`/order/detail/${params.id}`}>
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Order Detail"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <AttachFileIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Attachments"}/>
                </Box>
            </Box>
            <Box>
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <input type="hidden" {...register("oid")} value={params.id} />
                    <FileInput 
                        uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                        uploadPhotoFile={uploadPhotoFile}
                        uploadPhotoRef={uploadPhotoRef}
                        uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                        multiple={true}
                    />
                    <SubmitButton />
                </form>
                <Box display={"flex"} >
                {
                    data?.data?.map((item)=>(
                        <Box key={item.id} sx={{margin:"0.5rem",}}>
                                <Box sx={{cursor:"pointer", position:"absolute"}} 
                                    onClick={() =>{setImageId(item?.id); handleImageDelete();}}
                                 >
                                    <DeleteForeverIcon height="12px" sx={{color:"red", padding:"10px"}} />
                                </Box>
                                {item?.file?.endsWith('.pdf') || item?.file?.endsWith('.doc') || item?.file?.endsWith('.docx') ? (
                                    <a href={item?.file} target="_blank">
                                        <Box sx={{margin: "0.5rem",height: '200px',width:"200px",border:"1px solid brown", borderRadius:"5px",display:"flex",flexDirection:"column",alignItems:"center", justifyContent:"center" }}>
                                            <FilePresentIcon sx={{height: '140px',width:"140px", color:"var(--primary-color)" }}/>
                                            <p style={{color:"black"}}>{item?.file?.split('/').pop()}</p>
                                        </Box>
                                    </a>
                                ):
                                <img src={item?.file} alt="Uploaded" style={{height: '200px',width:"200px", objectFit:"cover" ,border:"1px solid brown", borderRadius:"5px" }} />}
                        </Box>
                    ))
                }
                </Box>
            </Box>

        </Layout>
    )
}

export default OrderAttachments;