import { Box } from "@mui/material";
import { SubTitle } from "./SubTitle";

const LabelTextarea = ({label,required,name,register, value}) => {

    const style = {
        border:"1px solid lightgrey", 
        padding:"5px",
        borderRadius:"5px",
        width:"250px",
        ':focus': {
            border: '2px solid red!important'
        }
      };

    return (
        <Box display={"flex"} gap={"5px"}>
            {
                label ? 
                <Box width={"120px"} height={"auto"} alignContent={"center"}>
                    <SubTitle title={label+(required?"*":"")} />
                </Box> : ""
            }
            {
                register ?
                <textarea {...register(name)} rows={4} style={style} defaultValue={value} />
                : <textarea rows={4} style={style} defaultValue={value}/>
            }
        </Box>
    )
}

export default LabelTextarea;