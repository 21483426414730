import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Audit from "../../../components/Audit";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect } from "react";

const OrderAudit = () =>{

    const params = useParams();

    const endpoints = [
        `/api/audits/?pid=${params?.id}`
    ]
    const {data,refetch} = useGetChainApi(endpoints);

    useEffect(()=>{
        refetch()
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/order/">
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Order"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Link to={`/order/detail/${params.id}`}>
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Order Detail"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Audit"}/>
                </Box>
            </Box>
            <Box>
                <Title title={"Update History"}/>
                {
                    data?.length > 0 &&
                    Array.isArray(data[0]?.data) &&
                    <Audit data={data[0]?.data} />
                }
            </Box>
        </Layout>
    )
}

export default OrderAudit;