import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { Link, useNavigate } from "react-router-dom";
import ReceiptIcon from '@mui/icons-material/Receipt';
import Counter from "../../../components/Counter";
import {useGetChainApi} from "../../../components/api/useGetChainApi";
import { useEffect, useState } from "react";
import { ORDER_STATUS } from "../../../constants/CONSTANTS";
import LabelSelect from "../../../components/LabelSelect";
import Pagination from "../../../components/Pagination";

const OrderList = () =>{

    const [search,setSearch] = useState();
    const navigate = useNavigate();
    const [orderStatus, setOrderStatus] = useState("ALL");

    const [page,setPage] = useState(1);

    const endPints = [
        `/api/orders/?status=${orderStatus}&page=${page}${search?("&search="+search):""}`
    ]
    const {data, refetch,isLoading} = useGetChainApi(endPints);

    useEffect(()=>{
        refetch();
    },[orderStatus,page,search,refetch])

    function onSelectChange(value){
        setOrderStatus(value)
    }

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Order"}/>
                    <LabelSelect onSelectChange={onSelectChange} data={ORDER_STATUS} />
                    <input onChange={(e)=>{setPage(1);setSearch(e.target.value);}} type="text" className={"inputText"} placeholder="Search"/>
                </Box>
                <Box display={"flex"} gap={"1rem"}>
                    <Link to="/order/add">
                        <Box display={"flex"} gap={"5px"} sx={{cursor:"pointer"}}>
                            <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Add Order"}/>
                        </Box>
                    </Link>
                </Box>
            </Box>

            <Box display={"flex"} gap={"10px"}>
                <Counter bg={`var(--light-brown)`} color={"var(--dark-brown)"} count={data && data[0]?.data?.results?.total_amount_this_week} label={"Sales This Week"} />
                <Counter bg={`var(--light-purple)`} color={"var(--dark-purple)"} count={data && data[0]?.data?.results?.total_amount_this_month} label={"Sales This Month"} />
                <Counter bg={`var(--light-green)`} color={"var(--dark-green)"} count={data && data[0]?.data?.results?.orders_due_count} label={"Due Invoice Count"} />
            </Box>

            {
                data?.length > 0 &&
                <Pagination setPage={setPage} next={ data && data[0]?.data?.next} previous={ data && data[0]?.data?.previous}/>
            }
            
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Date</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Invoice#</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Order#</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Customer</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Status</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Due Date</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Amount</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Due Amount</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {   
                        isLoading === false &&
                        data?.length > 0 &&
                        Array.isArray(data[0]?.data?.results?.orders) &&
                        data[0]?.data?.results?.orders?.map((item)=>(
                        <TableRow onClick={()=>navigate(`/order/detail/${item.id}`)} key={item.id} sx={{cursor:"pointer", '&:last-child td, &:last-child th': { border: 0 }}} >
                            <TableCell component="th" scope="row">
                            {item.orderDate?.split("T")[0]}
                            </TableCell>
                            <TableCell align="left">{item.invoiceNumber}</TableCell>
                            <TableCell align="left">{item.id}</TableCell>
                            <TableCell align="left">{item.customer?.name}</TableCell>
                            <TableCell align="left">{item.status}</TableCell>
                            <TableCell align="left">{item.dueDate?.split("T")[0]}</TableCell>
                            <TableCell align="left">Nrs.{item.totalAmount}</TableCell>
                            <TableCell align="left">Nrs.{parseFloat(item.totalAmount)-parseFloat(item.total_paid)}</TableCell>
                        </TableRow>
                        ))
                    }
                    {
                        isLoading &&
                        <Box>
                            Loading
                        </Box>
                    }
                </TableBody>
            </Table>
            
        </Layout>
    )
}

export default OrderList;