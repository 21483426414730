import { Link, useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import { Box, Tab, Tabs } from "@mui/material";
import { Title } from "../../../components/Title";
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LeftLabelText from "../../../components/LeftLabelText";
import { useEffect, useState } from "react";
import EditButton from "../../../components/EditButton";
import CustomTabPanel from "../../../components/CustomTabPanel";
import Counter from "../../../components/Counter";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import InventoryGroupListComponent from "../../inventory/list/InventoryGroupListComponent";
import Zoom from "react-medium-image-zoom";

const BrandDetail = () => {
    const params = useParams();

    const endPoints = [`/api/brands/${params.id}/`,`/api/inventories/?brand=${params.id}`];
    const {data, refetch:fetch} = useGetChainApi(endPoints);

    useEffect(()=>{
        fetch()
    },[])

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
      }

    useEffect(()=>{
        fetch();
    },[])
    

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/brand/">
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Brand"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Brand Detail"}/>
                </Box>
            </Box>
            {
                data?.length > 0 &&
                <>
                    <Box sx={{display:"flex", flexDirection:"column", gap:"5px"}}>
                        <Zoom>
                            <img alt="li ha logo" src={data[0]?.data?.logo} style={{width:"10rem", border:"1px solid brown", borderRadius:"5px"}} />
                        </Zoom>
                        <Title title={data[0]?.data?.name} />
                        <LeftLabelText label={"Color"} value={data[0]?.data?.color}/>
                        <Box fontSize={"12px"}>{data[0]?.data?.description}</Box>
                        <EditButton />
                    </Box>
                    <Box display={"flex"} gap={"10px"}>
                        <Counter bg={`var(--light-brown)`} color={"var(--dark-brown)"} count={data[0]?.data?.total_products} label={"Brand Products Count"} />
                    </Box>
                </>
            }


            <Box sx={{borderBottom:"1px solid lightgray", marginTop:"16px"}}></Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs" indicatorColor={'secondary'}>
                    <Tab label="Inventory By Brand" {...a11yProps(0)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                {
                    data?.length > 1 &&
                    <InventoryGroupListComponent data={data[1]?.data} />
                }
            </CustomTabPanel>
        </Layout>
    )
}

export default BrandDetail;