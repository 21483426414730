import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { Link, useNavigate } from "react-router-dom";
import { useGetApi } from "../../../components/api/useGetApi";
import { useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';

const CustomerList = ()=> {

    const navigate = useNavigate();

    const endPoint = `users/?type=NORMAL`;
    const {data, refetch:fetch} = useGetApi(endPoint);

    useEffect(()=>{
        fetch();
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <PersonIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Customer"}/>
                </Box>
                <Link to="/customer/add">
                    <Box display={"flex"} gap={"5px"} sx={{cursor:"pointer"}}>
                        <PersonIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Add Customers"}/>
                    </Box>
                </Link>
            </Box>

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Photo</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} >User Name</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Phone</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Email</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">User Type</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Status</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.isArray(data?.data) &&
                        data?.data?.map((item)=>(
                            <TableRow onClick={()=>navigate(`/customer/detail/${item.id}`)} key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor:"pointer"}} >
                                <TableCell component="th" scope="row">
                                    <img style={{height:"50px",borderRadius:"5px"}} src={item?.profile?.profilePicture} />
                                </TableCell>
                                <TableCell align="left">{item.first_name + " " + item.last_name}</TableCell>
                                <TableCell align="left">{item?.profile?.phone_ext + " " + item?.profile?.phone}</TableCell>
                                <TableCell align="left">{item?.email}</TableCell>
                                <TableCell align="left">{item?.profile?.userType}</TableCell>
                                <TableCell align="left">{item?.profile?.status}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Layout>
    )
}

export default CustomerList;