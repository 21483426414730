import { Link, useParams } from "react-router-dom";
import Layout from "../../../components/Layout"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { Title } from "../../../components/Title";
import { Box } from "@mui/material";
import LabelTextarea from "../../../components/LabelTextarea";
import LabelInput from "../../../components/LabelInput";
import FileInput from "../../../components/FileInput";
import usePostPut from "../../../components/api/usePostPut";
import SubmitButton from "../../../components/SubmitButton";
import { STATUS } from "../../../constants/CONSTANTS";
import LabelSelect from "../../../components/LabelSelect";
import { Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useState } from "react";

const AddInventoryItem =()=> {
    const params = useParams();

    const endPoint = `create-update-inventory-item/`; 
    const imageName = "images"; 
    const successMessage = "Item Added!"; 
    const redirection = `/inventory/detail/${params?.id}/`; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        control
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to={`/inventory/detail/${params?.id}/`}>
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Inventory Detail"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Add Inventory Item"}/>
                </Box>
            </Box>
            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Title title={"Inventory Item Details"} />
                        <LabelInput register={register} name="title" type={"text"} label="Item Name" required />
                        <LabelInput register={register} name="barcode" type={"text"} label="Barcode" required />
                        <LabelInput register={register} name="weight" type={"text"} label="Weight" required />
                        <LabelInput register={register} name="model" type={"text"} label="Modal" required />
                        <LabelInput register={register} name="color" type={"text"} label="Color" required />
                        <LabelInput register={register} name="tags" type={"text"} label="Tags" required />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Title title={"Pricing Details"} />
                        <LabelInput register={register} name="price" type={"number"} label="price" required />
                        <LabelInput register={register} name="costPrice" type={"number"} label="costPrice" required />
                        <LabelInput register={register} name="compareAtPrice" type={"number"} label="compareAtPrice" required />
                        <LabelInput register={register} name="quantity" type={"number"} label="Current Quantity" required />
                        <Title title={"Status"} />
                        <LabelSelect register={register} name={"inventoryStatus"} label="Inventory Status" data={STATUS} required/>
                        <input type="hidden" {...register("inventoryGroup")} value={params?.id} />
                        <input type="hidden" {...register("slug")} value={"fff"} />
                    </Box>
                </Box>
                <Box>
                        <Title title={"Images"} />
                        <FileInput 
                            uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                            uploadPhotoFile={uploadPhotoFile}
                            uploadPhotoRef={uploadPhotoRef}
                            uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                            multiple={true}
                        />
                </Box>
                <SubmitButton />
            </form>
        </Layout>
    )
}

export default AddInventoryItem;