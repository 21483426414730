import { useEffect, useState } from "react";
import LabelInput from "../../../components/LabelInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";
import VerifyOtp from "./VerifyOtp";

const ChangeEmail = () => {

    const [otpSent, setOtpSent] = useState(false);

    const endPoint = "profile/changeemail/"; 
    const imageName = null; 
    const successMessage = "OTP sent to email!"; 
    const redirection =null; 
    const type = "post";//using post for update, since we are using same api for create and update
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    useEffect(()=>{
        if(isSuccess){
            setOtpSent(true)
        }
    },[isSuccess])

    return (
        <>
            {
                !otpSent ? 
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <LabelInput register={register} name="email" type={"text"} label="New Email" required/>
                    <SubmitButton label={"Send OTP"} />
                </form>
                :<VerifyOtp />
            }
        </>
    )
}
export default ChangeEmail;