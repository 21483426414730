import { Box, Tab, Tabs } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import BusinessIcon from '@mui/icons-material/Business';
import Tag from "../../../components/Tag";
import LeftLabelText from "../../../components/LeftLabelText";
import { useEffect, useState } from "react";
import Counter from "../../../components/Counter";
import TopLabelText from "../../../components/TopLabelText";
import CustomTabPanel from "../../../components/CustomTabPanel";
import EditButton from "../../../components/EditButton";
import InventoryGroupListComponent from "../../inventory/list/InventoryGroupListComponent";
import { useGetChainApi } from "../../../components/api/useGetChainApi";

const VendorDetail = () => {

    const params = useParams();
    const endPoints = [`/api/vendors/${params.id}/`,`/api/inventories/?vendor=${params.id}`];
    const {data, refetch:fetch} = useGetChainApi(endPoints);

    useEffect(()=>{
        fetch();
    },[])

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
      }

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/vendor/">
                    <Box display={"flex"} gap={"5px"}>
                        <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Vendor"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Vendor Detail"}/>
                </Box>
            </Box>

            {
                data?.length > 0 &&
                <>
                    <Box sx={{display:"flex", flexDirection:"column", gap:"5px"}}>
                        <Box>
                            <Title title={data[0]?.data?.companyName} />
                            <Box display={"flex"} gap={"8px"}>
                                <Tag tag={data[0]?.data?.isVerified === true ? "Verified" : "Not Verified"} 
                                    bg={data[0]?.data?.isVerified === true ? "var(--light-green)":"var(--light-brown)"  } 
                                    color={data[0]?.data?.isVerified === true ? "var(--dark-green)":"var(--dark-brown)"}/>
                                <Tag tag={data[0]?.data?.vendorStatus} bg={"var(--light-brown)"} color={"var(--dark-brown)"}/>
                            </Box>
                        </Box>
                        <LeftLabelText label={"PAN No."} value={data[0]?.data?.pan}/>
                        <Box display={"flex"} gap={"10px"}>
                            <Counter bg={`var(--light-brown)`} color={"var(--dark-brown)"} count={data[0]?.data?.counters?.total_products} label={"Product Count"} />
                        </Box>
                    </Box>
                    <Box sx={{borderBottom:"1px solid lightgray", marginTop:"16px"}}></Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="tabs" indicatorColor={'secondary'}>
                            <Tab label="Vendor Details" {...a11yProps(0)} />
                            <Tab label="Vendor Products" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <EditButton/>
                        
                        <Box display={"flex"} flexWrap={"wrap"} gap={"15px"}>
                            <Box>
                                <Title title={"Contact Details"} />
                                <TopLabelText label="Primary Phone" value={data[0]?.data?.primaryPhone}/>
                                <TopLabelText label="Secondary Phone" value={data[0]?.data?.secondaryPhone}/>
                                <TopLabelText label="Primary Email" value={data[0]?.data?.primaryEmail}/>
                                <TopLabelText label="Secondary Email" value={data[0]?.data?.secondaryEmail}/>
                            </Box>

                            <Box>
                                <Title title={"Address"} />
                                <TopLabelText label="Street" value={data[0]?.data?.street}/>
                                <TopLabelText label="City" value={data[0]?.data?.city}/>
                                <TopLabelText label="State" value={data[0]?.data?.province}/>
                                <TopLabelText label="Postal Code" value={data[0]?.data?.postalCode}/>
                                <TopLabelText label="Country" value={data[0]?.data?.country}/>
                                <a href={data[0]?.data?.mapLink} target="_blank">
                                    <TopLabelText label="Map Link" value={data[0]?.data?.mapLink}/>
                                </a>
                            </Box>
                        </Box>
                    </CustomTabPanel>
                </>
            }

                <CustomTabPanel value={value} index={1}>
                    {
                        data?.length > 1 &&
                        <InventoryGroupListComponent data={data[1]?.data} />
                    }
                </CustomTabPanel>
        </Layout>
    )
}

export default VendorDetail;