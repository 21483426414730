import axios from "axios";
import { BASE_URL } from "../constants/CONSTANTS";

export const axiosNoAuth = axios.create({
  baseURL: BASE_URL,
});
axiosNoAuth.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
//response interceptor
axiosNoAuth.interceptors.response.use(function (response) {
    return response.data;
  }, function (error) {
    console.log("Something Went Wrong!")
    // return Promise.reject(error);
  });
  