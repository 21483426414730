import { Box } from "@mui/material";
import { SubTitle } from "./SubTitle";
import { Controller } from "react-hook-form";
import Select from "react-select";

const LabelSelectSearch = ({label,required,name, value,size, disabled,options,control,setSearch}) => {

    return (
        <Box display={"flex"} gap={"5px"}>
            {
                label &&
                <Box width={"120px"} height={"auto"} alignContent={"center"}>
                    <SubTitle title={label+(required?"*":"")} />
                </Box>
            }
            {
                control ?
                <Controller
                    render={({ field: { onChange, value, ref } }) => (
                        <Select
                            options={options}
                            ref={ref}
                            value={options?.find((c) => c?.value === value) ?? ''}
                            onChange={(val) => {
                                onChange(val.value);
                            }}
                            onInputChange={(inputValue) => {setSearch && setSearch(inputValue)}}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        />
                    )}
                    control={control}
                    name={name}
                    defaultValue={value?value:''}
                />
                :"Select doesn't have control"
            }
        </Box>
    )
}

export default LabelSelectSearch;