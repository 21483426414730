import { Box, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useNavigate, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import LeftLabelText from "../../../components/LeftLabelText";
import EditButton from "../../../components/EditButton";
import { useEffect, useState } from "react";
import CustomTabPanel from "../../../components/CustomTabPanel";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { CURRENCY } from "../../../constants/CONSTANTS";
import Zoom from 'react-medium-image-zoom'

const InventoryDetail =() =>{
    const params = useParams();
    const endPoints = [
        `/api/inventories/${params.id}/`,
        `${params.bid?'/api/get-branch-inventory-item/?branch='+params.bid:'/api/inventory-item/?group='+params.id}`,
        `/api/inventory-files/?gid=${params.id}&bid=${params.bid}`
    ]
    const {data, refetch:fetch} = useGetChainApi(endPoints);
    const navigate = useNavigate();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
      }

    useEffect(()=>{
        fetch();
    },[])

    return (
        <Layout>
            <Box display={"flex"} justifyContent={"space-between"} sx={{borderBottom:"1px solid lightgray"}}>
                <Box sx={{display:"flex",paddingBottom:"5px", gap:"5px"}}>
                    <Link to="/inventory/">
                        <Box display={"flex"} gap={"5px"}>
                            <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Inventory"}/>
                        </Box>
                    </Link>
                    <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Inventory Detail"}/>
                    </Box>
                </Box>
                {
                        params.bid ?"":
                        <Box display={'flex'} gap={"1rem"}>
                            <Link to={"item/add"}>
                                <Box display={"flex"} gap={"5px"}>
                                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                                    <Title title={"Add Inventory Item"}/>
                                </Box>
                            </Link>
                            <Link to={`item/quantity/edit`}>
                                <Box display={"flex"} gap={"5px"}>
                                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                                    <Title title={"Udate Item Quantity"}/>
                                </Box>
                            </Link>
                        </Box>
                }
            </Box>
            {
                data?.length > 0 &&
                <Box sx={{display:"flex", flexDirection:"column", gap:"5px"}}>
                    <Title title={data[0]?.data?.title} />
                    <LeftLabelText label={"Category"} value={data[0]?.data?.category?.name}/>
                    <LeftLabelText label={"Brand"} value={data[0]?.data?.brand?.name}/>
                    <LeftLabelText label={"Vendor"} value={data[0]?.data?.vendor?.companyName}/>
                    {
                        params.bid ?"":
                        (localStorage.getItem("userType") === "ADMIN" ? <EditButton /> : "")
                    }
                </Box>
            }

            <Box sx={{borderBottom:"1px solid lightgray", marginTop:"16px"}}></Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs" indicatorColor={'secondary'}>
                    <Tab label="Inventory Items" {...a11yProps(0)} />
                    <Tab label="Gallery" {...a11yProps(0)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead >
                        <TableRow >
                            <TableCell sx={{color:"var(--primary-color)", width:"5rem"}} >Item</TableCell>
                            <TableCell sx={{color:"var(--primary-color)", width:"5rem"}} >Modal</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} >Color</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Barcode</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Price</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Compare At Price</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Quantity</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.length > 1 &&
                            Array.isArray(data[1]?.data) &&
                            data[1]?.data?.map((item)=>(
                                <TableRow onClick={
                                    ()=>navigate( localStorage.getItem("userType") === "ADMIN" && (
                                    params.bid?
                                    `/branch/inventory/detail/${params.id}/${params.bid}/${item.id}/${item.branch_inventory_id}/edit`:
                                    `/inventory/detail/${params.id}/item/${item.id}/edit`))
                                    } 
                                    key={item.id} 
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } , cursor:"pointer"}} >
                                    <TableCell component="th" scope="row">{item.title}</TableCell>
                                    <TableCell align="left">{item.model}</TableCell>
                                    <TableCell align="left">{item.color}</TableCell>
                                    <TableCell align="left">{item.barcode}</TableCell>
                                    <TableCell align="left">{CURRENCY.filter((item)=>item.value === data[0]?.data?.currency)[0]?.label}{item.price}</TableCell>
                                    <TableCell align="left">{CURRENCY.filter((item)=>item.value === data[0]?.data?.currency)[0]?.label}{item.compareAtPrice}</TableCell>
                                    <TableCell align="left">{item.quantity}</TableCell>
                                    <TableCell align="left">{item.inventoryStatus}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Box display={"flex"} flexWrap={"wrap"}>
                    
                    {
                        data?.length > 0 && 
                        data[2]?.data?.map((item)=>(
                            <Box key={item.id} sx={{margin:"0.5rem",}}>
                                <Zoom>
                                    <img src={item.file} alt="Uploaded" style={{height: '200px',width:"200px", objectFit:"cover" ,border:"1px solid brown", borderRadius:"5px" }} />
                                </Zoom>
                            </Box>
                        ))
                    }
                </Box>
            </CustomTabPanel>
        </Layout>
    )
}

export default InventoryDetail;