import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import LabelInput from "../../../components/LabelInput";
import LabelSelect from "../../../components/LabelSelect";
import usePostPut from "../../../components/api/usePostPut";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import SubmitButton from "../../../components/SubmitButton";
import { CURRENCY } from "../../../constants/CONSTANTS";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LabelTextarea from "../../../components/LabelTextarea";
import FileInput from "../../../components/FileInput";
import Zoom from "react-medium-image-zoom";

const EditInventoryGroup =()=>{
    const [textValue, setTextValue] = useState("");
    const params = useParams();
    const endPoints = [
        '/api/categories/',
        '/api/brands/',
        '/api/vendors/?status=ACTIVE',
        `/api/inventories/${params.id}/`
    ]
    const {data,refetch} = useGetChainApi(endPoints);

    useEffect(()=>{
        refetch();
    },[])

    const endPoint = `inventories/${params.id}/`; 
    const imageName = "menuImage";
    const successMessage = "Inventory Group Updated!"; 
    const redirection = `/inventory/detail/${params.id}/`; 
    const type = "put";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        control,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

      useEffect(()=>{
        if(data && data[3]?.data){
            setTextValue(data[3]?.data?.moreDescription?data[3]?.data?.moreDescription:"");
            setValue('category', data[3]?.data?.category?.id);
            setValue('brand', data[3]?.data?.brand?.id);
            setValue('vendor', data[3]?.data?.vendor?.id);
            setValue('currency', data[3]?.data?.currency);
        }
    },[data])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to={`/inventory/detail/${params.id}/`}>
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Inventory Detail"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit Inventory Group"}/>
                </Box>
            </Box>
            {
            data?.length > 3 &&
            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Box>
                            <Title title={"Inventory Group"} />
                            <Box sx={{fontSize:"12px",fontStyle:"italic"}}>Note: Once you create group, you may add multiple products, models or colors of products.</Box>
                        </Box>
                        <input type="hidden" {...register("id")} value={params.id} />
                        <LabelInput value={data[3]?.data.title} register={register} name="title" type={"text"} label="Group Name"/>
                        {
                            data?.length > 0 &&
                            Array.isArray(data[0]?.data) &&
                            <LabelSelect register={register} name={"category"} label="Select Category" data={data[0]?.data.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))} required/>
                        }

                        {
                            data?.length > 1 &&
                            Array.isArray(data[1]?.data) &&
                            <LabelSelect register={register} name={"brand"} label="Select Brand" data={data[1]?.data.map(item => ({
                                value: item.id,
                                label: item.name,
                            }))} required/>
                        }

                        {
                            data?.length > 2 &&
                            Array.isArray(data[2]?.data) &&
                            <LabelSelect register={register} name={"vendor"} label="Select Vendor" data={data[2]?.data.map(item => ({
                                value: item.id,
                                label: item.companyName,
                            }))} required/>
                        }
                        <LabelInput register={register} value={data[3]?.data?.commission} name="commission" type={"number"} label="Commission %"/>
                        <LabelSelect register={register} name={"currency"} label="Select Currency" data={CURRENCY} required/>
                        <LabelTextarea register={register} value={data[3]?.data?.description}  name="description" label="Description" required />
                        <FileInput 
                            uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                            uploadPhotoFile={uploadPhotoFile}
                            uploadPhotoRef={uploadPhotoRef}
                            uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                            multiple={true}
                        />
                        <Zoom>
                            <img src={data[3]?.data?.menuImage} alt="Uploaded" style={{height: '200px',width:"200px", objectFit:"cover" ,border:"1px solid brown", borderRadius:"5px" }} />
                        </Zoom>

                        <Box mt={"15px"}>
                        <Title title={"More Description"} />
                        {
                            data &&
                            data?.length > 1 &&
                            <Controller
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={ textValue }
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setTextValue(data);
                                            onChange(data)
                                        }}
                                    />
                                )}
                                name='moreDescription'
                                control={control}
                                defaultValue=''
                                />
                        }
                    </Box>
                    </Box>
                </Box>
                <SubmitButton />
            </form>
            }
        </Layout>
    )
}

export default EditInventoryGroup;