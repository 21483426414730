import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import Person4Icon from '@mui/icons-material/Person4';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LabelInput from "../../../components/LabelInput";
import LabelSelect from "../../../components/LabelSelect";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";
import { COUNTRY, GENDER } from "../../../constants/CONSTANTS";
import FileInput from "../../../components/FileInput";

const AddCustomer = () =>{

    const endPoint = "create_update_customer_with_profile/"; 
    const imageName = "profilePicture"; 
    const successMessage = "Customer Added!"; 
    const redirection = "/customer"; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/user/">
                    <Box display={"flex"} gap={"5px"}>
                        <Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Customer"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Add Customer"}/>
                </Box>
            </Box>
            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                    <Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"User Details"} />
                            <LabelInput register={register} name="first_name" type={"text"} label="First Name" required />
                            <LabelInput register={register} name="last_name" type={"text"} label="Last Name" required/>
                            <LabelSelect register={register} name="gender" label="Gender" data={GENDER} required/>
                            <LabelInput register={register} name="dob" type={"date"} label="DOB"/>
                            
                            <Title title={"Contact Details"} />
                            <LabelInput register={register} value={"+977"} name="phone_ext" type={"text"} label="Phone Extension" required/>
                            <LabelInput register={register} name="phone" type={"phone"} label="Phone" required/>
                            <LabelInput register={register} name="email" type={"email"} label="Email" required/>
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Title title={"Address*"} />
                        <LabelInput register={register} name="street" type={"text"} label="Street" required/>
                        <LabelInput register={register} name="city" type={"text"} label="City" required/>
                        <LabelInput register={register} name="province" type={"text"} label="State" required/>
                        <LabelInput register={register} name="postalCode" type={"text"} label="Postal Code" required/>
                        <LabelSelect register={register} name="country" label="Country" data={COUNTRY} required/>
                    </Box>
                    <Box>
                        <Title title={"Profile Picture"} />
                        <Box display={"flex"}>
                            <FileInput 
                                uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                                uploadPhotoFile={uploadPhotoFile}
                                uploadPhotoRef={uploadPhotoRef}
                                uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                            />
                        </Box>
                    </Box>
                </Box>
                <SubmitButton />
            </form>
        </Layout>
    )
}
export default AddCustomer;