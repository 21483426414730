import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <Box sx={{background:"var(--primary-color)",padding:"12px", color:'white', display:"flex", justifyContent:"space-between"}}>
            <Box>
                Li Ha Motors
            </Box>
            <Box >
                <Link to={"/profile"} style={{color:"white"}}>
                    {
                        JSON.parse(localStorage.getItem('user'))?.first_name + " " + JSON.parse(localStorage.getItem('user'))?.last_name
                    }
                </Link>
            </Box>
        </Box>
    )
}

export default Navbar;