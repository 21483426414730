import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

const Layout = ({children}) =>{
    if(!ls.get("_lr")){
        window.location.href = "/admin/login"
        return <></>
    }
    return (
        <Box sx={{display:"flex"}}>
            <Sidebar />
            <Box sx={{width:"100%", minHeight:"100vh", display:"flex", flexDirection:"column"}}>
                <Navbar />
                <Box sx={{padding:"12px", display:"flex", flexDirection:"column", gap:"12px"}}>
                    {
                        children
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Layout;