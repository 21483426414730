import Layout from "../../../components/Layout"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import BusinessIcon from '@mui/icons-material/Business';
import { Box } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import { useEffect } from "react";
import SubmitButton from "../../../components/SubmitButton";
import LabelInput from "../../../components/LabelInput";
import LabelTextarea from "../../../components/LabelTextarea";
import LabelSelect from "../../../components/LabelSelect";
import { COUNTRY, STATUS, VERIFIED } from "../../../constants/CONSTANTS";
import { useGetApi } from "../../../components/api/useGetApi";
import usePostPut from "../../../components/api/usePostPut";

const EditVendor = () => {
    const params = useParams();
    const endPoint = `vendors/${params.id}/`; 

    const {data,refetch:fetch} = useGetApi(endPoint);

    const imageName = null; 
    const successMessage = "Vendor Updated!"; 
    const redirection = "/vendor"; 
    const type = "put";
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type);

    useEffect(()=>{
        fetch();
    },[])

    useEffect(()=>{
        if(data){
            setValue('country', data?.data?.country)
            setValue('vendorStatus', data?.data?.vendorStatus)
            setValue('isVerified', data?.data?.isVerified)
        }
    },[data])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/vendor/">
                    <Box display={"flex"} gap={"5px"}>
                        <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Vendor"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Vendor Detail"}/>
                </Box>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit"}/>
                </Box>
            </Box>
            {
                data?.data &&
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                        <Box>
                            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                                <Title title={"Company Details"} />
                                <input type="hidden"  {...register("id")} value={params.id} />
                                <LabelInput register={register} value={data?.data?.companyName} name="companyName" type={"text"} label="Company Name" required />
                                <LabelTextarea register={register} value={data?.data?.description} name="description" label="Description" required />
                                <LabelInput register={register} value={data?.data?.pan} name="pan" type={"text"} label="PAN NO."/>

                                <Title title={"Contact Details"} />
                                <LabelInput register={register} value={data?.data?.primaryPhone} name="primaryPhone" type={"phone"} label="Primary Phone" required/>
                                <LabelInput register={register} value={data?.data?.secondaryPhone} name="secondaryPhone" type={"phone"} label="Secondary Phone"/>
                                <LabelInput register={register} value={data?.data?.primaryEmail} name="primaryEmail" type={"email"} label="Primary Email" required/>
                                <LabelInput register={register} value={data?.data?.secondaryEmail} name="secondaryEmail" type={"email"} label="Secondary Email"/>
                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Address*"} />
                            <LabelInput register={register} value={data?.data?.street} name="street" type={"text"} label="Street" required/>
                            <LabelInput register={register} value={data?.data?.city} name="city" type={"text"} label="City" required/>
                            <LabelInput register={register} value={data?.data?.province} name="province" type={"text"} label="State" required/>
                            <LabelInput register={register} value={data?.data?.postalCode} name="postalCode" type={"text"} label="Postal Code" required/>
                            <LabelSelect register={register} name="country" label="Country" data={COUNTRY} required/>
                            <LabelInput register={register} value={data?.data?.mapLink} name="mapLink" type={"text"} label="Map Link"/>
                            <Title title={"Status"} />
                            <LabelSelect register={register} name={"vendorStatus"} label="Vendor Status" data={STATUS} required/>
                            <LabelSelect register={register} name="isVerified" label="Is Verified?" data={VERIFIED} required/>
                        </Box>
                    </Box>
                    <SubmitButton />
                </form>
            }
        </Layout>
    )
}

export default EditVendor;