import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const Audit = ({data}) =>{
    return (
        <Table aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Old Value</TableCell>
                <TableCell>New Value</TableCell>
                <TableCell>Field</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell>parent id</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {
                    data?.map((item)=>(
                        <TableRow
                        key={item.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {item.oldValue}
                        </TableCell>
                        <TableCell>{item.newValue}</TableCell>
                        <TableCell>{item.column}</TableCell>
                        <TableCell>{item.user}</TableCell>
                        <TableCell>{item.date?.split("T")[0]}</TableCell>
                        <TableCell>{item.remark}</TableCell>
                        <TableCell>{item.parentid}</TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )
}
export default Audit;