import { useEffect, useState } from "react";

import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import LabelSelect from "../../../components/LabelSelect";
import LabelInput from "../../../components/LabelInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";
import { ORDER_STATUS } from "../../../constants/CONSTANTS";

const OrderCountByBranch = () =>{

    const endPointPost = `order-count-by-branch/`; 
    const imageName = null; 
    const successMessage = "Report Generated!"; 
    const redirection = null; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess:success,
        handleSubmit,
        register,
        onFinalSubmit,
        data:response,
      } = usePostPut(endPointPost,imageName,successMessage,redirection,type,goToDetailedPage);

    const [mappedArray, setMappedArray] = useState([]);
    useEffect(()=>{
        if(response?.data){
            setMappedArray([])
            for (const key in response?.data) {
                if (response?.data.hasOwnProperty(key)) {
                    // Push an object with the key-value pair into the array
                    setMappedArray(prev => [
                        ...prev,
                        { name: key, value: response?.data[key] }
                    ] )
                }
            }
        }
    },[response])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", flexDirection:'column', gap:"10px"}}>
                <Box display={"flex"} gap={"5px"}>
                    <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Order Count by branch and Status"}/>
                </Box>
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} sx={{flexWrap:"wrap", gap:"10px"}}>
                        <LabelSelect label={"Status"} hasAllField={true} register={register} name={"status"} data={ORDER_STATUS} />
                        <LabelInput register={register} name={"start_date"} type={"date"} label={"Start Date"} required />
                        <LabelInput register={register} name={"end_date"} type={"date"} label={"End Date"} required />
                        <SubmitButton label={"Run"} noMargin={true} />
                    </Box>
                </form>
            </Box>

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Branch</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Count</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        console.log("mappedArray",)
                    }
                    {
                        mappedArray?.length > 0 &&
                        mappedArray?.map((item) => (
                            <TableRow key={1} sx={{cursor:"pointer", '&:last-child td, &:last-child th': { border: 0 }}} >
                                <TableCell align="left">{item.name === "null"?"Not Assigned":item.name}</TableCell>
                                <TableCell align="left">{item.value}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            
        </Layout>
    )
}

export default OrderCountByBranch;