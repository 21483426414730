import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import StoreIcon from '@mui/icons-material/Store';
import LeftLabelText from "../../../components/LeftLabelText";
import LabelSelect from "../../../components/LabelSelect";
import SubmitButton from "../../../components/SubmitButton";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect } from "react";
import LabelInput from "../../../components/LabelInput";
import LabelTextarea from "../../../components/LabelTextarea";
import usePostPut from "../../../components/api/usePostPut";
import Audit from "../../../components/Audit";
import { ACTION } from "../../../constants/CONSTANTS";

const BranchInventoryEdit = ()=>{

    const params = useParams();

    const endpoints = [
        `/api/inventory-item/${params?.iid}/`,
        `/api/branch-inventory/${params?.biid}/`,
        `/api/audits/?id=${params?.biid}`
    ]
    const {data,refetch} = useGetChainApi(endpoints);

    useEffect(()=>{
        refetch()
    },[])

    const endPoint = `edit-branch-inventory-item/`; 
    const imageName = null; 
    const successMessage = "Branch Inventory Updated!"; 
    const redirection = `/branch/inventory/detail/${params.gid}/${params.bid}/`; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/brand/">
                    <Box display={"flex"} gap={"5px"}>
                        <StoreIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Branch"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Branch Inventory"}/>
                </Box>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit"}/>
                </Box>
            </Box>
            <Box display={"flex"} gap={"40px"} flexWrap={"wrap"}>
                <Box display={"flex"} flexDirection={"column"}>

                    <Title title={"Edit Branch Inventory"}/>
                    <Box fontStyle={"italic"} fontSize={"13px"}  maxWidth={"400px"}>You are updating available quantity of this product for this branch. From this form, you can add new stock from Admin/Head office or return stock to Admin/Head Office.</Box>
                    <br></br>
                    <form onSubmit={handleSubmit(onFinalSubmit)}>
                        <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
                            {
                                data?.length > 0 &&
                                <>
                                    <LeftLabelText label={"Inventory Item"} value={data[0]?.data?.title} />
                                    <LeftLabelText label={"Modal"} value={data[0]?.data?.model} />
                                    <LeftLabelText label={"Color"} value={data[0]?.data?.color} />
                                    <LeftLabelText label={"Admin Quantity"} value={"You cannot add more than " + data[0]?.data?.quantity} />
                                    <LeftLabelText label={"Branch Quantity"} value={"This branch currently has " + data[1]?.data?.quantity} />
                                </>
                            }
                            <input type="hidden" {...register("biid")} value={params.biid} />
                            <LabelSelect register={register} name={"action"} label={"Action"} data={ACTION} value={"asdf"} />
                            <LabelInput register={register} name={"quantity"} label={"quantity"} type={"number"} required={true}  />
                            <LabelTextarea register={register} name={"remark"} label={"Remark"} required={true} />
                        </Box>
                        <SubmitButton />
                    </form>
                </Box>
                <Box>
                    <Title title={"Update History"}/>
                    {
                        data?.length > 1 &&
                        Array.isArray(data[2]?.data) &&
                        <Audit data={data[2]?.data} />
                    }
                </Box>
            </Box>
        </Layout>
    )
}

export default BranchInventoryEdit;