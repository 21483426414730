import { Box } from "@mui/material";
import Layout from "../../../components/Layout";

const Dashboard = () => {
    return (
        <Layout>
            Dashboard
        </Layout>
    )
}

export default Dashboard;