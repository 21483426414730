import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { usePostPutApi } from "../../../components/api/usePostPutApi";
import { toast } from "react-toastify";

export default function useAddOrder(endPoint){
    const { postPutApiMutation } = usePostPutApi(endPoint,"post");
    const { isLoading, isError, error, isSuccess, data } = postPutApiMutation;
    const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
      } = useForm();

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormProvider)
        name: "item", // unique name for your Field Array
      });

      const onFinalSubmit = (values) => {
        postPutApiMutation.mutate(values);
      };

      useEffect(() => {
        if (isSuccess) {
            toast.success("New Order Placed", {
                position: "top-right",
                autoClose: 2000,});
              window.location.href = `/order/`;
        }
    
        if (isError) {
            
            toast.error(error?.response?.data?.error, {
              position: "top-right",
              autoClose: 2000,});
            toast.error(error?.request?.response, {
              position: "top-right",
              autoClose: 2000,});
          }
        }, [
          postPutApiMutation.isSuccess,
          postPutApiMutation.isLoading,
          postPutApiMutation.isError,
        ]);

        const uploadPhotoRef = useRef(null);

        function uploadPhotoOnClickHandler() {
          uploadPhotoRef.current.click();
        }

        function uploadPhotoChangeHandler(event) {
          SetUploadPhotoFile(Array.from(event.target.files));
        }

      return {
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        watch,
        setValue,
        getValues,
        fields, 
        append, 
        remove,
        control
      };
    
}