import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { Link, useParams } from "react-router-dom";
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LabelInput from "../../../components/LabelInput";
import FileInput from "../../../components/FileInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";
import { useEffect } from "react";
import { useGetChainApi } from "../../../components/api/useGetChainApi";

const EditCategory =() => {

    const params = useParams();
    const endPoint = `categories/${params.id}/`;
    const endPoints = [`/api/categories/${params.id}/`];

    const {data, refetch:fetch} = useGetChainApi(endPoints);

    useEffect(()=>{
        fetch();
    },[])

    const imageName = "icon"; 
    const successMessage = "Category Updated!"; 
    const redirection = "/category"; 
    const type = "put";
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
      } = usePostPut(endPoint,imageName,successMessage,redirection,type);
console.log(data)
    return (
        <Layout>
           <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/category/">
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricBikeIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Category"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricBikeIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit Category"}/>
                </Box>
            </Box>
            {
                data?.length > 0 &&
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Category Details"} />
                            <input {...register("id")} type="hidden" value={data[0]?.data?.id} />
                            <LabelInput  register={register} name="name" value={data[0]?.data?.name} type={"text"} label="Category Name" required />
                        </Box>
                        <Box>
                            <Title title={"Icon"} />
                            <FileInput 
                                uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                                uploadPhotoFile={uploadPhotoFile}
                                uploadPhotoRef={uploadPhotoRef}
                                uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                                image={data[0]?.data?.icon}
                            />
                        </Box>
                    </Box>
                    <SubmitButton />
                </form>
            }
        </Layout>
    )
}

export default EditCategory;