import { useEffect, useState } from "react";

import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { Link, useNavigate } from "react-router-dom";
import Counter from "../../../components/Counter";
import LabelSelect from "../../../components/LabelSelect";
import { useGetApi } from "../../../components/api/useGetApi";
import LabelInput from "../../../components/LabelInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";
import { ORDER_STATUS } from "../../../constants/CONSTANTS";

const SalesReport = () =>{

    const navigate = useNavigate();

    const [status,setStatus] = useState("ALL");
    const endPoint = `branches/?status=ALL`;
    const {data,refetch:fetch,isSuccess} = useGetApi(endPoint);

    useEffect(()=>{
        if(localStorage.getItem("userType") === "ADMIN"){
            fetch()
        }
    },[status, fetch])

    const endPointPost = `order-report/`; 
    const imageName = null; 
    const successMessage = "Report Generated!"; 
    const redirection = null; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess:success,
        handleSubmit,
        register,
        onFinalSubmit,
        data:response,
      } = usePostPut(endPointPost,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", flexDirection:'column', gap:"10px"}}>
                <Box display={"flex"} gap={"5px"}>
                    <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Sales Report"}/>
                </Box>
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} sx={{flexWrap:"wrap", gap:"10px"}}>
                        {
                            Array.isArray(data?.data) &&
                            localStorage.getItem("userType") === "ADMIN" &&
                            <LabelSelect label={"Branch"} hasAllField={true} register={register} name={"branch"} data={data?.data.map((item)=>({
                                                label:item.companyName,
                                                value:item.id
                                            }))} />
                        }
                        <LabelSelect label={"Status"} hasAllField={true} register={register} name={"status"} data={ORDER_STATUS} />
                        <LabelInput register={register} name={"start_date"} type={"date"} label={"Start Date"} required />
                        <LabelInput register={register} name={"end_date"} type={"date"} label={"End Date"} required />
                        <SubmitButton label={"Run"} noMargin={true} />
                    </Box>
                </form>
            </Box>

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Date</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Invoice#</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Order#</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Customer</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Status</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Due Date</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Amount</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Due Amount</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {   
                        isLoading === false &&
                        Array.isArray(response?.data?.orders) &&
                        response?.data?.orders?.map((item)=>(
                        <TableRow onClick={()=>navigate(`/order/detail/${item.id}`)} key={item.id} sx={{cursor:"pointer", '&:last-child td, &:last-child th': { border: 0 }}} >
                            <TableCell component="th" scope="row">
                            {item.orderDate?.split("T")[0]}
                            </TableCell>
                            <TableCell align="left">{item.invoiceNumber}</TableCell>
                            <TableCell align="left">{item.id}</TableCell>
                            <TableCell align="left">{item.customer?.name}</TableCell>
                            <TableCell align="left">{item.status}</TableCell>
                            <TableCell align="left">{item.dueDate?.split("T")[0]}</TableCell>
                            <TableCell align="left">Nrs.{item.totalAmount}</TableCell>
                            <TableCell align="left">Nrs.{parseFloat(item.totalAmount)-parseFloat(item.total_paid)}</TableCell>
                        </TableRow>
                        ))
                    }
                    {
                        isLoading &&
                        <Box>
                            Loading
                        </Box>
                    }
                </TableBody>
            </Table>
            
        </Layout>
    )
}

export default SalesReport;