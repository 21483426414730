import { Button } from "@mui/material"

const SubmitButton = ({label, noMargin}) => {
    return (
        <Button type="submit" variant="contained" 
            sx={{marginTop:noMargin?"0px":"20px",minWidth:"150px", background:"var(--secondary-color)", color:"white", paddingTop:"10px", ":hover":{
                background:"var(--primary-color)"
            }}}>{label?label:"Save"}</Button>
    )
}
export default SubmitButton;