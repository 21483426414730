import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import Person4Icon from '@mui/icons-material/Person4';
import { Title } from "../../../components/Title";
import { Link, useNavigate } from "react-router-dom";
import UserListComponent from "./UserListComponent";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { useEffect } from "react";

const UserList = ()=> {


    const endPoint = [`/api/users/?type=BRANCH_AND_ADMIN`];
    const {data, refetch:fetch} = useGetChainApi(endPoint);

    useEffect(()=>{
        fetch();
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", justifyContent:"space-between"}}>
                <Box display={"flex"} gap={"5px"}>
                    <Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"User"}/>
                </Box>
                {
                    localStorage.getItem("userType") === "ADMIN" &&
                        <Link to="/user/add">
                            <Box display={"flex"} gap={"5px"} sx={{cursor:"pointer"}}>
                                <Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />
                                <Title title={"Add User"}/>
                            </Box>
                        </Link>
                
                }
            </Box>
            {
                data?.length > 0 &&
                Array.isArray(data[0]?.data) &&
                <UserListComponent data={data[0]?.data} />
            }
        </Layout>
    )
}

export default UserList;