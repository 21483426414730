import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link, useParams } from "react-router-dom";
import { Title } from "../../../components/Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import LabelInput from "../../../components/LabelInput";
import LabelSelect from "../../../components/LabelSelect";
import SubmitButton from "../../../components/SubmitButton";
import { STATUS } from "../../../constants/CONSTANTS";
import { useEffect, useState } from "react";
import LabelTextarea from "../../../components/LabelTextarea";
import FileInput from "../../../components/FileInput";
import usePostPut from "../../../components/api/usePostPut";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDeleteApi } from "../../../components/api/useDeleteApi";
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import Audit from "../../../components/Audit";
import { Controller } from "react-hook-form";
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditInventoryItem =()=>{
    const params = useParams();
    const [textValue, setTextValue] = useState("");

    const endPoints = [`/api/inventory-item/${params?.iid}/`,`/api/audits/?id=${params?.iid}`]; 

    const {data,refetch}= useGetChainApi(endPoints);

    const [imageId, setImageId] = useState(0);
    let endpoint = `inventory-files/${imageId}/`
    const {deleteApiMutation} = useDeleteApi(endpoint);

    useEffect(()=>{
        refetch();
    },[deleteApiMutation?.isSuccess])

    const updateEndpoint = 'create-update-inventory-item/'
    const imageName = "images"; 
    const successMessage = "Item Added!"; 
    const redirection =`/inventory/detail/${params?.id}/`; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        control,
        setValue
      } = usePostPut(updateEndpoint,imageName,successMessage,redirection,type,goToDetailedPage);

    useEffect(()=>{
        if(data){
            setValue('inventoryStatus', data[0]?.data?.inventoryStatus)
            setValue('barcode', data[0]?.data?.barcode)
            setValue('weight', data[0]?.data?.weight)
            setValue('model', data[0]?.data?.model)
            setValue('color', data[0]?.data?.color)
            setValue('tags', data[0]?.data?.tags)
            setValue('price', data[0]?.data?.price)
            setValue('costPrice', data[0]?.data?.costPrice)
            setValue('compareAtPrice', data[0]?.data?.compareAtPrice)
        }
    },[data])

    function handleImageDelete(id){
        setTimeout(()=>{
            deleteApiMutation.mutate();
        },100)
    }

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to={`/inventory/detail/${params.id}/`}>
                    <Box display={"flex"} gap={"5px"}>
                        <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Inventory Detail"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Edit Inventory Item"}/>
                </Box>
            </Box>
            {
                data?.length > 0 && 
                params.iid == data[0]?.data?.id &&
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Inventory Item Details"} />
                            <input {...register("id")} type="hidden" value={data[0]?.data?.id} />
                            <LabelInput register={register} value={data[0]?.data?.title}  name="title" type={"text"} label="Item Name" required />
                            <LabelInput register={register} value={data[0]?.data?.barcode}  name="barcode" type={"text"} label="Barcode" required />
                            <LabelInput register={register} value={data[0]?.data?.weight}  name="weight" type={"text"} label="Weight" required />
                            <LabelInput register={register} value={data[0]?.data?.model}  name="model" type={"text"} label="Modal" required />
                            <LabelInput register={register} value={data[0]?.data?.color}  name="color" type={"text"} label="Color" required />
                            <LabelInput register={register} value={data[0]?.data?.tags}  name="tags" type={"text"} label="Tags" required />
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Pricing Details"} />
                            <LabelInput register={register} value={data[0]?.data?.price}  name="price" type={"number"} label="price" required />
                            <LabelInput register={register} value={data[0]?.data?.costPrice}  name="costPrice" type={"number"} label="costPrice" required />
                            <LabelInput register={register} value={data[0]?.data?.compareAtPrice}  name="compareAtPrice" type={"number"} label="compareAtPrice" required />
                            <Title title={"Status"} />
                            <LabelSelect register={register} name={"inventoryStatus"} label="Inventory Status" data={STATUS} required/>
                            <input type="hidden" {...register("slug")} value={"slug"} />
                        </Box>
                    </Box>
                    
                    <Box>
                            <Title title={"Images"} />
                            <Box display={"flex"}>
                                <FileInput 
                                    uploadPhotoOnClickHandler={uploadPhotoOnClickHandler} 
                                    uploadPhotoFile={uploadPhotoFile}
                                    uploadPhotoRef={uploadPhotoRef}
                                    uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                                    multiple={true}
                                />
                                {
                                    data[0]?.data?.files?.map((item)=>(
                                        <Box key={item.id} sx={{margin:"0.5rem",}}>
                                                <Box sx={{cursor:"pointer", position:"absolute"}} onClick={() =>{setImageId(item?.id); handleImageDelete();}} >
                                                    <DeleteForeverIcon height="12px" sx={{color:"red", padding:"10px"}} />
                                                </Box>
                                                <img src={item.file} alt="Uploaded" style={{height: '200px',width:"200px", objectFit:"cover" ,border:"1px solid brown", borderRadius:"5px" }} />
                                        </Box>
                                    ))
                                }
                            </Box>
                    </Box>
                    <SubmitButton />
                </form>
            }
            <Box>
                <Title title={"Update History"}/>
                {
                    data?.length > 1 &&
                    Array.isArray(data[1]?.data) &&
                    <Audit data={data[1]?.data} />
                }
            </Box>
        </Layout>
    )
}

export default EditInventoryItem;