import Layout from "../../../components/Layout";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import LabelInput from "../../../components/LabelInput";
import LabelSelect from "../../../components/LabelSelect";
import CloseIcon from '@mui/icons-material/Close';
import LabelTextarea from "../../../components/LabelTextarea";
import { SubTitle } from "../../../components/SubTitle";
import SubmitButton from "../../../components/SubmitButton";
import { ORDER_STATUS, SHIPMENT_STATUS, STATUS, TAX } from "../../../constants/CONSTANTS";
import useAddOrder from "./useAddOrder";
import {useGetChainApi} from "../../../components/api/useGetChainApi";
import {useGetApi} from "../../../components/api/useGetApi";
import { useEffect, useState } from "react";
import LabelSelectSearch from "../../../components/LabelSelectSearch";

const AddOrder = ()=> {

    const endpoint = ['/api/inventory-item/']
    const {data, refetch} = useGetChainApi(endpoint);
    const [subTotal,setSubTotal] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [total, setTotal] = useState(0);
    const [adj, setAdj] = useState(0); 
    const [search,setSearch] = useState("");
    const {data:customer, refetch:fetch} = useGetApi(`users/?type=NORMAL&search=${search}`)

    useEffect(()=>{
        refetch();
    },[])

    useEffect(()=>{
        fetch()
    },[search])

    const {
        handleSubmit,
        register,
        onFinalSubmit,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile,
        watch,
        setValue,
        getValues,
        fields, 
        append, 
        remove,
        control
      } = useAddOrder("create-order-with-items/");

      function calculateAmount(qty,rate,dis,tax){
        const totalDiscount =dis * (qty * rate)/100;
        const totalTax = tax*((qty * rate) - dis * (qty * rate)/100)/100;
        const subTotal = qty * rate;
        const total = (subTotal - totalDiscount) + totalTax;
        return {total,subTotal,totalTax,totalDiscount};
      }

      const regex = /item\.(\d+)\./;
      const regexItem = /item\.(\d+)\.inventoryItem/;

      useEffect(() => {
          const subscription = watch((value, { name, type }) =>
          {
            console.log(value,name)
            if(name === "customer"){
                setSearch(value.customer)
            }
            if(name === "adjustmentAmount"){
                setAdj(parseFloat(value.adjustmentAmount));
            }

            const itemMatch = name.match(regexItem);
            const match = name.match(regex);

            if(itemMatch){
                if(data?.length > 0){
                    setValue(`item.${itemMatch[1]}.rate`,data[0]?.data?.filter((item)=>item.id === parseInt(getValues(`item.${itemMatch[1]}.inventoryItem`)))[0]?.price)
                }
            }
            if(match){
                setSubTotal(0);
                setTotalDiscount(0);
                setTotal(0);
                setTotalTax(0);
                value?.item?.map((item)=> 
                {
                    setSubTotal((prev)=>
                        prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                        ,parseFloat(item.discount),parseInt(item.tax)).subTotal
                    )
                    setTotalDiscount((prev)=>
                        prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                        ,parseFloat(item.discount),parseInt(item.tax)).totalDiscount
                    )
                    setTotalTax((prev)=>
                        prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                        ,parseFloat(item.discount),parseInt(item.tax)).totalTax
                    )
                    setTotal((prev)=>
                        prev+calculateAmount(parseInt(item.quantity),parseFloat(item.rate)
                        ,parseFloat(item.discount),parseInt(item.tax)).total
                    )
                }
                )
                
                document.getElementById(`item.${match[1]}`).innerHTML = calculateAmount(
                        getValues(`item.${match[1]}.quantity`),
                        parseFloat(getValues(`item.${match[1]}.rate`)),
                        parseFloat(getValues(`item.${match[1]}.discount`)),
                        parseFloat(getValues(`item.${match[1]}.tax`))
                    ).total
            }
          }
        )
        return () => subscription.unsubscribe()
      }, [watch,data])

    return (
        <Layout>
           <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/order/">
                    <Box display={"flex"} gap={"5px"}>
                        <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Order"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Add Order"}/>
                </Box>
            </Box>
            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                    <Title title={"New Invoice/Order"}/>
                    <LabelSelect register={register} name={"status"} label={"Invoice Status"} data={ORDER_STATUS} required />
                    <LabelSelect register={register} name={"shipmentStatus"} label={"SHIPMENT Status"} data={SHIPMENT_STATUS} required />
                    <LabelSelectSearch label={"Customer"} setSearch={setSearch} control={control} name={"customer"} options={customer?.data?.map((item)=>(
                        {
                            value:item.id,
                            label:item.first_name + " " + item.last_name 
                        }
                    ))} />
                    <LabelInput register={register} name={"invoiceNumber"} label={"Invoice Number"} required />
                    <LabelInput register={register} name={"orderDate"} type={"date"} label={"Invoice Date"} required />
                    <LabelInput register={register} name={"dueDate"} type={"date"} label={"Due Date"} required />
                    <LabelInput register={register} name={"invoiceSubject"} label={"Invoice Subject"} required />

                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead >
                        <TableRow >
                            <TableCell sx={{color:"var(--primary-color)"}} >Item Detail</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Quantity</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Rate</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Discount</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Tax</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Amount</TableCell>
                            <TableCell sx={{color:"var(--primary-color)"}} align="left">Action</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {fields.map((field, index) => (
                            <TableRow key={field.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                <TableCell component="th" scope="row">
                                    {
                                        data?.length > 0 &&
                                        <LabelSelectSearch 
                                            name={`item.${index}.inventoryItem`}
                                            control={control}
                                            options={
                                                data[0]?.data?.map((item)=>(
                                                    {
                                                        label:item.title+"--"+item.quantity,
                                                        value:item.id
                                                    }
                                                ))
                                            } />
                                    }

                                </TableCell>
                                <TableCell align="left"><LabelInput register={register} name={`item.${index}.quantity`} size={"50px"} type={"number"} value={0} /></TableCell>
                                <TableCell align="left"><LabelInput register={register} name={`item.${index}.rate`} disabled size={"100px"} type={"number"} value={0} /></TableCell>
                                <TableCell align="left"><LabelInput register={register} name={`item.${index}.discount`} size={"100px"} type={"number"} value={0} /></TableCell>
                                <TableCell align="left"><LabelSelect register={register} name={`item.${index}.tax`} size={"100px"} data={TAX} value={0}/></TableCell>
                                <TableCell align="left">
                                    Nrs.<div id={`item.${index}`}></div>
                                </TableCell>
                                <TableCell align="left">
                                    <CloseIcon onClick={()=>remove(index)} height="12px" sx={{color:"red", padding:"10px", cursor:"pointer"}} />
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    <Button onClick={()=>append({})}>Add Item</Button>

                    <LabelTextarea register={register} name={"invoiceFooter"} label={"Footer Message"} />

                    <Box sx={{background:"var(--light-brown)", padding:"16px", borderRadius:"5px", display:"flex", flexDirection:"column", gap:"10px"}}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <SubTitle title={"Sub Total"} />
                            <SubTitle title={"Nrs."+subTotal} />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <SubTitle title={"Total Discount"} />
                            <SubTitle title={"Nrs."+totalDiscount} />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <SubTitle title={"Total Tax"} />
                            <SubTitle title={"Nrs."+totalTax} />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <SubTitle title={"Total"} />
                            <SubTitle title={"Nrs."+total} />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Box sx={{display:"flex", gap:"10px"}}>
                                <LabelInput register={register} name={"adjustmentTitle"} type={"text"} value={"Adjustment"} />
                                <LabelInput min={"negative"} register={register} name={"adjustmentAmount"} type={"number"} value={0} />
                            </Box>
                            <SubTitle title={adj} />
                        </Box>
                        <Box height={"1px"} borderBottom={"1px solid var(--dark-brown)"}></Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Title title={"Total"} />
                            <Title title={"Nrs."+(parseFloat(total)+parseFloat(adj))} />
                        </Box>
                    </Box>
                    <Title title={"Any Remark or Note?"} />
                    <LabelTextarea register={register} name={"remark"} label={"Note/remark"} />

                    <Box position={"fixed"} right={"16px"} bottom={"16px"}>
                        <SubmitButton label={"Create New Order/Invoice"}/>
                    </Box>
                </Box>
            </form>
        </Layout>
    )
}

export default AddOrder;