import { Box, Tooltip } from "@mui/material";
import { Title } from "./Title";
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreIcon from '@mui/icons-material/Store';
import BusinessIcon from '@mui/icons-material/Business';
import Person4Icon from '@mui/icons-material/Person4';
import PersonIcon from '@mui/icons-material/Person';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import PaymentsIcon from '@mui/icons-material/Payments';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import { SubTitle } from "./SubTitle";
import AssessmentIcon from '@mui/icons-material/Assessment';
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { useContext } from "react";
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StorefrontIcon from '@mui/icons-material/Storefront';

import { AppContext } from "../AppContext";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

const Sidebar = () => {

    const { expand, setExpand } = useContext(AppContext);

    return (
        <Box sx={{bgcolor:"#f6f6f6",width:expand?"250px":"30px", minHeight:"auto", display:"flex", flexDirection:"column", gap:"5px", padding:"12px"}}>
            {
                expand ?
                <CloseIcon onClick={()=>setExpand(!expand)} sx={{cursor:"pointer", color:"var(--primary-color)"}}/>
                :<MenuIcon onClick={()=>setExpand(!expand)} sx={{cursor:"pointer", color:"var(--primary-color)"}}/>
            }

            {/* <SidebarMenu 
                expand={expand}
                icon={<HomeIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                title={"Home"}
                path="/"
            /> */}

            <hr style={{width:"100%", borderColor:"#f6f6f6"}} />

            <Title title={expand?"Front Office":"FO"}/>
            {
                localStorage.getItem("userType") === "ADMIN" ?
                <>
                    <SidebarMenu 
                        expand={expand}
                        icon={<ElectricMopedIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                        title={"Brand"}
                        path="/brand"
                    />
                    <SidebarMenu 
                        expand={expand}
                        icon={<StoreIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                        title={"Branches"}
                        path="/branch"
                    />
                    <SidebarMenu 
                        expand={expand}
                        icon={<BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                        title={"Vendors"}
                        path={"/vendor"}
                    />
                </>:null
            }
        
            <SidebarMenu 
                expand={expand}
                icon={<Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />} 
                title={"Users"}
                path={"/user"}
            />
            <SidebarMenu 
                expand={expand}
                icon={<PersonIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                title={"Customers"}
                path={"/customer"}
            />
            <SidebarMenu 
                expand={expand}
                icon={<ElectricCarIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                title={"Inventory"}
                path={"/inventory"}
            />

            <hr style={{width:"100%", borderColor:"#f6f6f6"}} />

            <Title title={expand?"Back Office":"BO"}/>
            <SidebarMenu 
                expand={expand}
                icon={<ShoppingCartIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                title={"Orders"}
                path={"/order"}
            />
            <SidebarMenu 
                expand={expand}
                icon={<PaymentsIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                title={"Payments"}
                path={"/payment"}
            />
            {
                localStorage.getItem("userType") === "ADMIN" &&
                <SidebarMenu 
                    expand={expand}
                    icon={<LeakAddIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                    title={"Link Share Commissions"}
                    path={"/affiliate-links"}
                />
            }
            <SidebarMenu 
                expand={expand}
                icon={<AssessmentIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                title={"Reports"}
                path={"/reports"}
            />
            
            {
                localStorage.getItem("userType") === "ADMIN" ?
                <>
                    <hr style={{width:"100%", borderColor:"#f6f6f6"}} />

                    <Title title={expand?"Settings":"ST"}/>
                    <SidebarMenu 
                        expand={expand}
                        icon={<ElectricBikeIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                        title={"Categories"}
                        path={"/category"}
                    />
                    <SidebarMenu 
                        expand={expand}
                        icon={<ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                        title={"Invoice Setting"}
                        path={"/invoice-setting"}
                    />
                </>:null
            }

            <hr style={{width:"100%", borderColor:"#f6f6f6"}} />
                    
            <Box  onClick={()=>{
                ls.removeAll();
                localStorage.clear();
                window.location.href = "/admin/login";
            }}>
                <SidebarMenu 
                    expand={expand}
                    icon={<LogoutIcon height="18px" sx={{color:"var(--primary-color)"}} />} 
                    title={"Logout"}
                />
            </Box>
        </Box>
    )
}

const SidebarMenu = ({icon,title,path,expand}) => {
    return (
        <Link to={path?path:"/"} style={{textDecoration:"none"}}>
            <Tooltip title={title} placement="right">
                <Box  sx={{display:"flex", alignItems:'center', gap:"5px", height:"25px", cursor:"pointer"}} >
                    {icon}
                    {
                        expand && 
                        <Box height={"100%"} alignContent={"center"} pt={"3px"}>
                            <SubTitle title={title} />
                        </Box>
                    }
                </Box>
            </Tooltip>
        </Link>
    )
}

export default Sidebar;