import { Box } from "@mui/material";
import { Title } from "./Title";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const Pagination = ({title,setPage,next, previous}) => {
    return (
        <Box display={"flex"} justifyContent={"space-between"} >
            <Box><Title title={title?title:"Recent Orders"} /></Box>
            <Box display={"flex"} gap={"20px"}>
                {
                    previous &&
                    <Box onClick={()=>setPage(prev=>prev-1)} sx={{display:"flex",alignItems:"center", cursor:"pointer", fontSize:"12px", color:"brown"}}><KeyboardDoubleArrowLeftIcon sx={{color:"var(--primary-color)"}} /> Previous Page</Box>
                }
                {
                    next &&
                    <Box onClick={()=>setPage(prev=>prev+1)} sx={{display:"flex",alignItems:"center", cursor:"pointer", fontSize:"12px", color:"brown"}}>Next Page <KeyboardDoubleArrowRightIcon sx={{color:"var(--primary-color)"}} /> </Box>
                }
            </Box>
        </Box>
    )
}
export default Pagination;