import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import BusinessIcon from '@mui/icons-material/Business';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import usePostPut from "../../../components/api/usePostPut";
import LabelInput from "../../../components/LabelInput";
import LabelTextarea from "../../../components/LabelTextarea";
import LabelSelect from "../../../components/LabelSelect";
import { COUNTRY, STATUS, VERIFIED } from "../../../constants/CONSTANTS";
import SubmitButton from "../../../components/SubmitButton";

const AddVendor = () => {

    const endPoint = "vendors/"; 
    const imageName = null; 
    const successMessage = "Vendor Added!"; 
    const redirection = "/vendor"; 
    const type = "post";
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit
      } = usePostPut(endPoint,imageName,successMessage,redirection,type);

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/vendor/">
                    <Box display={"flex"} gap={"5px"}>
                        <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Vendor"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <BusinessIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Add Vendor"}/>
                </Box>
            </Box>

            <form onSubmit={handleSubmit(onFinalSubmit)}>
                <Box display={"flex"} gap="40px" flexWrap={"wrap"}>
                    <Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                            <Title title={"Company Details"} />
                            <LabelInput register={register} name="companyName" type={"text"} label="Company Name" required />
                            <LabelTextarea register={register} name="description" label="Description" required />
                            <LabelInput register={register} name="pan" type={"text"} label="PAN NO."/>

                            <Title title={"Contact Details"} />
                            <LabelInput register={register} name="primaryPhone" type={"phone"} label="Primary Phone" required/>
                            <LabelInput register={register} name="secondaryPhone" type={"phone"} label="Secondary Phone"/>
                            <LabelInput register={register} name="primaryEmail" type={"email"} label="Primary Email" required/>
                            <LabelInput register={register} name="secondaryEmail" type={"email"} label="Secondary Email"/>
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Title title={"Address*"} />
                        <LabelInput register={register} name="street" type={"text"} label="Street" required/>
                        <LabelInput register={register} name="city" type={"text"} label="City" required/>
                        <LabelInput register={register} name="province" type={"text"} label="State" required/>
                        <LabelInput register={register} name="postalCode" type={"text"} label="Postal Code" required/>
                        <LabelSelect register={register} name="country" label="Country" data={COUNTRY} required/>
                        <LabelInput register={register} name="mapLink" type={"text"} label="Map Link"/>
                        <Title title={"Status"} />
                        <LabelSelect register={register} name={"vendorStatus"} label="Vendor Status" data={STATUS} required/>
                        <LabelSelect register={register} name="isVerified" label="Is Verified?" data={VERIFIED} required/>
                    </Box>
                </Box>
                <SubmitButton />
            </form>

        </Layout>
    )
}

export default AddVendor;