import { Modal, TableCell, TableRow } from "@mui/material";
import Tag from "../../../components/Tag";
import { useEffect, useState } from "react";
import ModelCard from "../../../components/ModelCard";
import { Title } from "../../../components/Title";
import SubmitButton from "../../../components/SubmitButton";
import LabelSelect from "../../../components/LabelSelect";
import { DISCARDED, PAID, VERIFIED } from "../../../constants/CONSTANTS";
import { SubTitle } from "../../../components/SubTitle";
import usePostPut from "../../../components/api/usePostPut";
import { useNavigate, useParams } from "react-router-dom";

const AffiliateConversionEdit = ({item}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const params = useParams()
    const navigate = useNavigate()

    const endPoint = `edit-affiliate-conversion-status/`; 
    const imageName = null; 
    const successMessage = "Conversion Updated!"; 
    const redirection = `/affiliate-links/detail/${params.id}`; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
        register,
        onFinalSubmit,
        setValue
      } = usePostPut(endPoint,imageName,successMessage,redirection,type,goToDetailedPage);

    useEffect(()=>{
        if(item){
            setValue('isVerified', item.isVerified)
            setValue('isPaid', item.isPaid)
            setValue('isDiscarded', item.isDiscarded)
        }
    },[item])

    return (
        <>
            <TableRow key={item.id} sx={{'&:last-child td, &:last-child th': { border: 0 }, cursor:"pointer"}} >
                <TableCell onClick={()=>navigate(`/order/detail/${item.order}`)} component="th" scope="row">{item.order}</TableCell>
                <TableCell onClick={handleOpen} align="left">
                    <Tag tag={item?.isVerified === true ? "Verified" : "Not Verified"} 
                        bg={item?.isVerified === true ? "var(--light-green)":"var(--light-brown)"  } 
                        color={item?.isVerified === true ? "var(--dark-green)":"var(--dark-brown)"}/>
                </TableCell>
                <TableCell onClick={handleOpen} align="left">
                    <Tag tag={item?.isPaid === true ? "Paid" : "Not Paid"} 
                        bg={item?.isPaid === true ? "var(--light-green)":"var(--light-brown)"  } 
                        color={item?.isPaid === true ? "var(--dark-green)":"var(--dark-brown)"}/>
                </TableCell>
                <TableCell onClick={handleOpen} align="left">
                    <Tag tag={!item?.isDiscarded === true ? "Not Discarded" : "Discarded"} 
                        bg={!item?.isDiscarded === true ? "var(--light-green)":"var(--light-brown)"  } 
                        color={!item?.isDiscarded === true ? "var(--dark-green)":"var(--dark-brown)"}/>
                </TableCell>
                <TableCell align="left">{item.commission}</TableCell>
            </TableRow>
            {
                localStorage.getItem("userType") === "ADMIN" && 
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <form onSubmit={handleSubmit(onFinalSubmit)}>
                        <ModelCard>
                            <Title title={"Update Conversion Status"} />
                            <SubTitle bold={true} title={`Order#${item.order}`} />
                            
                            <input type="hidden" {...register("id")} value={item.id} />
                            <LabelSelect register={register} name={"isVerified"} label="is Verified?" data={VERIFIED} required />
                            <LabelSelect register={register} name={"isPaid"} label="is Paid?" data={PAID} required />
                            <LabelSelect register={register} name={"isDiscarded"} label="is Discarded?" data={DISCARDED} required />
                            
                            <SubmitButton label={"Update COnversion"} />
                        </ModelCard>
                    </form>
                </Modal>
            }
        </>
    )
}

export default AffiliateConversionEdit;