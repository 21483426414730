import { Box } from "@mui/material";
import Layout from "../../../components/Layout"
import { Link } from "react-router-dom";
import { Title } from "../../../components/Title";
import ReceiptIcon from '@mui/icons-material/Receipt';
import LeftLabelText from "../../../components/LeftLabelText";
import EditButton from "../../../components/EditButton";
import { useGetApi } from "../../../components/api/useGetApi";
import { useEffect } from "react";
import Zoom from "react-medium-image-zoom";

const InvoiceSetting =() => {

    const endPoint = 'invoice-setting/1/';
    const {data,refetch} = useGetApi(endPoint);

    useEffect(()=>{
        refetch();
    },[])

    return (
        <Layout>
            <Box display={"flex"} justifyContent={"space-between"} sx={{borderBottom:"1px solid lightgray"}}>
                <Box sx={{display:"flex",paddingBottom:"5px", gap:"5px"}}>
                    <Link to="/invoice-setting">
                        <Box display={"flex"} gap={"5px"}>
                            <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                            <Title title={"Invoice Setting"}/>
                        </Box>
                    </Link>
                </Box>
            </Box>
            <Box>
                <Title title="Invoice Setting" />
                <Box fontStyle={"italic"} fontSize={"13px"}  maxWidth={"400px"}>
                    IMPORTANT: Once you update these invoice settings, these settings will be applied to all future invoices. These changes will not be applied to past invoices.
                </Box>
                <br></br>
                <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
                    <Zoom>
                        <img alt="liha invoice logo" src={data?.data?.invoiceLogo} style={{width:"10rem", border:"1px solid brown", borderRadius:"5px"}} />
                    </Zoom>
                    <LeftLabelText label={"Company Name"} value={data?.data?.invoiceFrom} />
                    <LeftLabelText label={"Company Address"} value={data?.data?.invoiceFromAddress} />
                    <LeftLabelText label={"Company Email"} value={data?.data?.invoiceFromEmail}/>
                    <LeftLabelText label={"Company Phone"} value={data?.data?.invoiceFromPhone}/>
                    <LeftLabelText label={"Company Web"} value={data?.data?.invoiceFromWeb} />
                    <Box fontSize={"12px"}  maxWidth={"400px"}>
                        {data?.data?.invoiceFooter}
                    </Box>
                    <EditButton />
                </Box>
            </Box>
        </Layout>
    )
}

export default InvoiceSetting;