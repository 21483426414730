import React,{ Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-medium-image-zoom/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './AppContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider, createTheme } from '@mui/material';
import EditProfile from './pages/profile/edit/EditProfile';
import PaymentList from './pages/payment/list/PaymentList';
import AddCustomer from './pages/customer/add/AddCustomer';
import EditCustomer from './pages/customer/edit/EditCustomer';

import BranchList from './pages/branch/list/BranchList';
import Dashboard from './pages/dashboard/view/Dashboard';
import AddBranch from './pages/branch/add/AddBranch';
import Login from './pages/login/Login';
import BranchDetail from './pages/branch/view/BranchDetail';
import BranchEdit from './pages/branch/edit/BranchEdit';
import BrandList from './pages/brand/list/BrandList';
import AddBrand from './pages/brand/add/AddBrand';
import EditBrand from './pages/brand/edit/EditBrand';
import BrandDetail from './pages/brand/view/BrandDetail';
import VendorList from './pages/vendor/list/VendorList';
import AddVendor from './pages/vendor/add/AddVendor';
import EditVendor from './pages/vendor/edit/EditVendor';
import VendorDetail from './pages/vendor/view/VendorDetail';
import CategoryList from './pages/category/list/CategoryList';
import AddCategory from './pages/category/add/AddCategory';
import EditCategory from './pages/category/edit/EditCategory';
import InventoryList from './pages/inventory/list/InventoryList';
import AddInventoryGroup from './pages/inventory/add/AddInventoryGroup';
import InventoryDetail from './pages/inventory/view/InventoryDetail';
import AddInventoryItem from './pages/inventory/add/AddInventoryItem';
import EditInventoryGroup from './pages/inventory/edit/EditInventoryGroup';
import EditInventoryItem from './pages/inventory/edit/EditInventoryItem';
import UserList from './pages/user/list/UserList';
import AddUser from './pages/user/add/AddUser';
import UserDetail from './pages/user/view/UserDetail';
import EditUser from './pages/user/edit/EditUser';
import CustomerList from './pages/customer/list/CustomerList';
import CustomerDetail from './pages/customer/view/CustomerDetail';
import AddInventoryToBranch from './pages/branch/add/AddInventoryToBranch';
import BranchInventoryEdit from './pages/branch/edit/BranchInventoryEdit';
import EditInventoryItemQuantity from './pages/inventory/edit/EditInventoryItemQuantity';
import InvoiceSetting from './pages/invoice_setting/view/InvoiceSetting';
import InvoiceSettingEdit from './pages/invoice_setting/edit/InvoiceSettingEdit';
import AddOrder from './pages/order/add/AddOrder';
import OrderList from './pages/order/list/OrderList';
import OrderDetail from './pages/order/view/OrderDetail';
import EditOrder from './pages/order/edit/EditOrder';
import OrderAudit from './pages/order/view/OrderAudit';
import OrderAttachments from './pages/order/view/OrderAttachments';
import ProfileDetail from './pages/profile/view/ProfileDetail';
import AffiliateLinksList from './pages/affiliate-links/list/AffiliateLinksList';
import AffiliateLinkDetail from './pages/affiliate-links/view/AffiliateLinkDetail';
import ReportList from './pages/report/ReportList';
import ProductReport from './pages/report/product/ProductReport';
import OrderCountByBranach from './pages/report/order-count-by-branch/OrderCountByBranch';
import AffiliateReport from './pages/report/affiliate/AffiliateReport';
import SalesReport from './pages/report/sales/SalesReport';
import Frontend from './pages/frontend';
import CompanyDetailEdit from './pages/frontend/company/CompanyDetailEdit';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
  defaultOptions: {
     queries: {
        staleTime: Infinity,
        structuralSharing: false
     }
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#996335', // Custom primary color using hexadecimal code
    },
    secondary: {
      main: '#b7977c', // Custom secondary color using hexadecimal code
    },
  },

})

root.render(
  <React.StrictMode>
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/admin/login" element={<Login />} />
              <Route path="/profile" exact element={<ProfileDetail />} />
              <Route path="/profile/edit" exact element={<EditProfile />} />
              <Route path="/branch" exact element={<BranchList />} />
              <Route path="/branch/add" exact element={<AddBranch />} />
              <Route path="/branch/inventory/add" exact element={<AddInventoryToBranch />} />
              <Route path="/branch/detail/:id" exact element={<BranchDetail />} />
              <Route path="/branch/detail/:id/edit" exact element={<BranchEdit />} />
              <Route path='/brand' exact element={<BrandList />} />
              <Route path="/brand/add" exact element={<AddBrand />} />
              <Route path="/brand/detail/:id" exact element={<BrandDetail />} />
              <Route path="/brand/detail/:id/edit" exact element={<EditBrand />} />
              <Route path='/vendor' exact element={<VendorList />} />
              <Route path='/vendor/add' exact element={<AddVendor />} />
              <Route path="/vendor/detail/:id" exact element={<VendorDetail />} />
              <Route path='/vendor/detail/:id/edit' exact element={<EditVendor />} />
              <Route path='/category' exact element={<CategoryList />} />
              <Route path='/category/add' exact element={<AddCategory />} />
              <Route path="/category/detail/:id/edit" exact element={<EditCategory />} />
              <Route path='/inventory' exact element={<InventoryList />} />
              <Route path='/inventory/add' exact element={<AddInventoryGroup />} />
              <Route path="/inventory/detail/:id" exact element={<InventoryDetail />} />
              <Route path="branch/inventory/detail/:id/:bid/" exact element={<InventoryDetail />} />
              <Route path="branch/inventory/detail/:gid/:bid/:iid/:biid/edit" exact element={<BranchInventoryEdit />} />
              <Route path="/inventory/detail/:id/edit" exact element={<EditInventoryGroup />} />
              <Route path="/inventory/detail/:id/item/add" exact element={<AddInventoryItem />} />
              <Route path="/inventory/detail/:id/item/:iid/edit" exact element={<EditInventoryItem />} />
              <Route path="/inventory/detail/:id/item/quantity/edit" exact element={<EditInventoryItemQuantity />} />
              <Route path="/user" exact element={<UserList />} />
              <Route path="/user/add" exact element={<AddUser />} />
              <Route path="/user/detail/:id" exact element={<UserDetail />} />
              <Route path="/user/detail/:id/edit" exact element={<EditUser />} />
              <Route path="/customer" exact element={<CustomerList />} />
              <Route path="/customer/add" exact element={<AddCustomer />} />
              <Route path="/customer/detail/:id" exact element={<CustomerDetail />} />
              <Route path="/customer/detail/:id/edit" exact element={<EditCustomer />} />
              <Route path="/invoice-setting" exact element={<InvoiceSetting />} />
              <Route path="/invoice-setting/edit" exact element={<InvoiceSettingEdit />} />
              <Route path="/order" exact element={<OrderList />} />
              <Route path="/order/detail/:id" exact element={<OrderDetail />} />
              <Route path="/order/add" exact element={<AddOrder />} />
              <Route path="/order/detail/:id/edit" exact element={<EditOrder />} />
              <Route path="/order/detail/audit/:id" exact element={<OrderAudit />} />
              <Route path="/order/detail/attachments/:id" exact element={<OrderAttachments />} />
              <Route path="/payment" exact element={<PaymentList />} />
              <Route path="/affiliate-links" exact element={<AffiliateLinksList />} />
              <Route path="/affiliate-links/detail/:id" exact element={<AffiliateLinkDetail />} />
              <Route path="/reports" exact element={<ReportList />} />
              <Route path="/reports/product-performance" exact element={<ProductReport />} />
              <Route path="/reports/order-count-by-branch" exact element={<OrderCountByBranach />} />
              <Route path="/reports/affiliate-report" exact element={<AffiliateReport />} />
              <Route path="/reports/sales-report" exact element={<SalesReport />} />
              <Route path="/store" exact element={<Frontend />} />
              <Route path="/store/company-edit" exact element={<CompanyDetailEdit />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
        <ToastContainer />
      </QueryClientProvider>
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
