import { useEffect, useState } from "react";
import Layout from "../../../components/Layout"
import { useGetChainApi } from "../../../components/api/useGetChainApi";
import { Box, Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";
import Person4Icon from '@mui/icons-material/Person4';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Title } from "../../../components/Title";
import Tag from "../../../components/Tag";
import LeftLabelText from "../../../components/LeftLabelText";
import Counter from "../../../components/Counter";
import CustomTabPanel from "../../../components/CustomTabPanel";
import EditButton from "../../../components/EditButton";
import TopLabelText from "../../../components/TopLabelText";
import Zoom from "react-medium-image-zoom"
import ChangePassword from "../edit/ChangePassword";
import ChangeEmail from "../edit/ChangeEmail";

const ProfileDetail = () => {

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
      }

    const endPoints = [`/api/profile/`];
    const {data,refetch} = useGetChainApi(endPoints)

    useEffect(()=>{
        refetch();
    },[])

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", gap:"5px"}}>
                <Link to="/home/">
                    <Box display={"flex"} gap={"5px"}>
                        <Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />
                        <Title title={"Home"}/>
                    </Box>
                </Link>
                <KeyboardDoubleArrowRightIcon height="18px" sx={{color:"var(--primary-color)"}} />
                <Box display={"flex"} gap={"5px"}>
                    <Person4Icon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Profile"}/>
                </Box>
            </Box>
            {
                data?.length > 0 &&
                <>
                    <Box sx={{display:"flex", flexDirection:"column", gap:"5px"}}>
                        <Box>
                            <Zoom>
                                <img alt="profile picture" src={data[0]?.data?.profile?.profilePicture} style={{width:"6rem", height:"6rem", objectFit:"cover", border:"1px solid brown", borderRadius:"5px"}} />
                            </Zoom>
                            <Title title={data[0]?.data?.first_name + " " + data[0]?.data?.last_name} />
                            <Box display={"flex"} gap={"8px"}>
                                <Tag tag={data[0]?.data?.profile?.isVerified === true ? "Verified" : "Not Verified"} 
                                    bg={data[0]?.data?.profile?.isVerified === true ? "var(--light-green)":"var(--light-brown)"  } 
                                    color={data[0]?.data?.profile?.isVerified === true ? "var(--dark-green)":"var(--dark-brown)"}/>
                                <Tag tag={data[0]?.data?.profile?.status} bg={"var(--light-brown)"} color={"var(--dark-brown)"}/>
                                <Tag tag={data[0]?.data?.profile?.userType} bg={"var(--light-brown)"} color={"var(--dark-brown)"}/>
                            </Box>
                        </Box>
                        <LeftLabelText label={"Branch"} value={data[0]?.data?.profile?.branch?.companyName}/>

                        <Box display={"flex"} gap={"10px"}>
                            <Counter bg={`var(--light-brown)`} color={"var(--dark-brown)"} count={"99999"} label={"Total Link Shared"} />
                        </Box>
                    </Box>     
                    <Box sx={{borderBottom:"1px solid lightgray", marginTop:"16px"}}></Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="tabs" indicatorColor={'secondary'}>
                                <Tab label="User Details" {...a11yProps(0)} />
                                <Tab label="Shared Links" {...a11yProps(1)} />
                                <Tab label="Change Password" {...a11yProps(2)} />
                                <Tab label="Change Email" {...a11yProps(3)} />
                            </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                            <EditButton/>
                            
                            <Box display={"flex"} flexWrap={"wrap"} gap={"15px"}>
                                <Box>
                                    <Title title={"Personal Details"} />
                                    <TopLabelText label="DOB" value={data[0]?.data?.profile?.dob}/>
                                    <TopLabelText label="DOB" value={data[0]?.data?.profile?.gender}/>
                                </Box>
                                <Box>
                                    <Title title={"Contact Details"} />
                                    <TopLabelText label="Phone" value={data[0]?.data?.profile?.phone_ext + " " +data[0]?.data?.profile?.phone}/>
                                    <TopLabelText label="Email" value={data[0]?.data?.email}/>
                                </Box>

                                <Box>
                                    <Title title={"Address"} />
                                    <TopLabelText label="Street" value={data[0]?.data?.profile?.street}/>
                                    <TopLabelText label="City" value={data[0]?.data?.profile?.city}/>
                                    <TopLabelText label="State" value={data[0]?.data?.profile?.province}/>
                                    <TopLabelText label="Postal Code" value={data[0]?.data?.profile?.postalCode}/>
                                    <TopLabelText label="Country" value={data[0]?.data?.profile?.country}/>
                                    
                                </Box>
                            </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                            Link shared
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <ChangePassword />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <ChangeEmail />
                    </CustomTabPanel>
                </>
            }

        </Layout>
    )
}
export default ProfileDetail;