import { useEffect, useState } from "react";

import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import Layout from "../../../components/Layout"
import { Title } from "../../../components/Title";
import { Link, useNavigate } from "react-router-dom";
import Counter from "../../../components/Counter";
import LabelSelect from "../../../components/LabelSelect";
import { useGetApi } from "../../../components/api/useGetApi";
import LabelInput from "../../../components/LabelInput";
import SubmitButton from "../../../components/SubmitButton";
import usePostPut from "../../../components/api/usePostPut";

const ProductReport = () =>{

    const navigate = useNavigate();

    const [status,setStatus] = useState("ALL");
    const endPoint = `branches/?status=ALL`;
    const {data,refetch:fetch,isSuccess} = useGetApi(endPoint);

    useEffect(()=>{
        fetch()
    },[status, fetch])

    const endPointPost = `product-report/`; 
    const imageName = null; 
    const successMessage = "Report Generated!"; 
    const redirection = null; 
    const type = "post";
    const goToDetailedPage= false;
    const {
        isLoading,
        error,
        isSuccess:success,
        handleSubmit,
        register,
        onFinalSubmit,
        data:response,
      } = usePostPut(endPointPost,imageName,successMessage,redirection,type,goToDetailedPage);

    return (
        <Layout>
            <Box sx={{display:"flex", borderBottom:"1px solid lightgray",paddingBottom:"5px", flexDirection:'column', gap:"10px"}}>
                <Box display={"flex"} gap={"5px"}>
                    <ReceiptIcon height="18px" sx={{color:"var(--primary-color)"}} />
                    <Title title={"Order Performance Report"}/>
                </Box>
                <form onSubmit={handleSubmit(onFinalSubmit)}>
                    <Box display={"flex"} sx={{flexWrap:"wrap", gap:"10px"}}>
                        {
                            Array.isArray(data?.data) &&
                            <LabelSelect hasAllField={true} register={register} name={"branch"} data={data?.data.map((item)=>({
                                                label:item.companyName,
                                                value:item.id
                                            }))} />
                        }
                        <LabelInput register={register} name={"start_date"} type={"date"} label={"Start Date"} required />
                        <LabelInput register={register} name={"end_date"} type={"date"} label={"End Date"} required />
                        <SubmitButton label={"Run"} noMargin={true} />
                    </Box>
                </form>
            </Box>

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead >
                <TableRow >
                    <TableCell sx={{color:"var(--primary-color)"}} >Product Name</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Paid Count</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Due Count</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Proforma Count</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Returned Count</TableCell>
                    <TableCell sx={{color:"var(--primary-color)"}} align="left">Cancelled Count</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                        {
                            response &&
                            Array.isArray(response?.data) &&
                            response?.data?.map((item)=>(
                                <TableRow key={item.id} sx={{cursor:"pointer", '&:last-child td, &:last-child th': { border: 0 }}} >
                                    <TableCell component="th" scope="row">
                                    {item.name}
                                    </TableCell>
                                    <TableCell align="left">{item.paid_count}</TableCell>
                                    <TableCell align="left">{item.due_count}</TableCell>
                                    <TableCell align="left">{item.proforma_count}</TableCell>
                                    <TableCell align="left">{item.returned_count}</TableCell>
                                    <TableCell align="left">{item.cancelled_count}</TableCell>
                                </TableRow>
                            ))
                        }
                </TableBody>
            </Table>
            
        </Layout>
    )
}

export default ProductReport;