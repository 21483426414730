import { useMutation } from "react-query";
import { authorizationAxios } from "../../axios/authorizationAxios";

export const usePostPutApi = (endpoint,type) => {
  async function postPutApi(values) {
    let res;

    if(type === "post"){
      res = await authorizationAxios.post(
        `/api/${endpoint}`,
        values
      );
    }else{
      res = await authorizationAxios.put(
        `/api/${endpoint}`,
        values
      );
    }
    
    return res;
  }

  const postPutApiMutation = useMutation(postPutApi);
        
return {
  postPutApiMutation,
};
};